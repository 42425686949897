import {createTheme} from '@mui/material';
import {blueGrey, cyan, pink} from '@mui/material/colors';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#c9cdeb',
      mid: '#7B73C9',
      main: '#515abb',
      dark: '#002884',
      contrastText: '#fff',
      darkText: '#000',
      green: '#26A69A',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    warning: {
      main: '#bc52b3',
    },
    info: {
      main: '#527ebc',
    },
    success: {
      main: '#b3bc52',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    button: {
      fontWeight: 500,
      lineHeight: 2,
    },
    body3: {
      fontFamily: 'Poppins',
      fontSize: '0.8rem', // Custom size for body1
      fontWeight: 800, // Custom weight
      // Add more properties as needed
    },
    body4: {
      fontFamily: 'Poppins',
      fontSize: '0.8rem', // Custom size for body1
      fontWeight: 400, // Custom weight
      // Add more properties as needed
    },
    caption: {
      fontFamily: 'Poppins',
      fontSize: '0.8rem', // Custom size for caption
      color: 'grey', // Custom color
      // Add more properties as needed
    },
    h1: {
      fontFamily: 'Poppins',
      fontSize: '2rem', // Adjust as needed
      fontWeight: 'bold', // Adjust as needed
      color: '#515abb', // Adjust as needed
      // Add other styles as necessary
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: '1.5rem', // Adjust as needed
      fontWeight: 400, // Adjust as needed
      color: '#000', // Adjust as needed
      // Add other styles as necessary
    },
    h2light: {
      fontFamily: 'Poppins',
      fontSize: '1.5rem', // Adjust as needed
      color: '#fff', // Adjust as needed
      // Add other styles as necessary
    },
    bodylight: {
      fontFamily: 'Poppins',
      fontSize: '0.8rem', // Adjust as needed
      color: '#fff', // Adjust as needed
      // Add other styles as necessary
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: pink['A200'],
    },
    secondary: {
      main: cyan['A400'],
    },
    background: {
      default: blueGrey['800'],
      paper: blueGrey['700'],
    },
  },
});

