import React from 'react';
import TwoRowGrid from '../cards/peopleCard';
import Grid from '@mui/material/Grid';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import demoImg from '../../../assets/images/conversational-feedback.png';
import {productData} from '../../../models/product';

/**
 * Desktop Footer component
 * @return {JSX.Element} - Rendered component
 */
export default function ProductGrid(
    {imgData, width, justify, imgWidth, showBackground}) {
  return (
    <section id='product'>
      <Grid container spacing={1}>
        <Grid item xs={12} >
          <Box
            sx={{
              display: {xs: 'none',
                sm: 'flex'},
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
            }}
          >
            <img
              src={demoImg}
              alt={'A screenshot of the ProSocial app showing a '+
              'conversation between a group of people.'}
              loading="lazy"
              style={{maxWidth: `600px`}}
            />
          </Box>
        </Grid>
        <Grid container spacing={1}>
          {imgData.map((slide, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box>
                <TwoRowGrid
                  imgData={slide}
                  width={width}
                  justify={justify}
                  imgWidth={imgWidth}
                  showBackground={showBackground}
                />
              </Box>
            </Grid>
          ))}
        </Grid>

      </Grid>
    </section>
  );
};

ProductGrid.propTypes = {

  /** *
     * a list of people information
     */
  width: PropTypes.number,
  justify: PropTypes.number,
  imgWidth: PropTypes.number,
  showBackground: PropTypes.bool,
  imgData: PropTypes.shape([{
    image: PropTypes.object.isRequired,
    desc: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string, // optional
    text: PropTypes.string.isRequired,
  }]).isRequired,
};

ProductGrid.defaultProps = {
  width: 200,
  justify: 'start',
  height: 50,
  imgWidth: 50,
  showBackground: false,
  imgData: productData,
};

