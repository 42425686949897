/* eslint-disable max-len */
import {Box, Typography, Paper, Grid, Button} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
const GroupCard = (
    {roomName,
      handleGroupClick}) => {
  const passGroupClick = () => {
    console.log('group name clicked', roomName);
    handleGroupClick(roomName);
  };
  return (
    <Button id='group-parent-button-container' variant='contained'
      color='primary'
      onClick={roomName? passGroupClick : undefined}
      style={{
        width: '85%',
        margin: '10px'}}>
      <Paper sx={{
        width: '100%',
        display: 'flex',
        padding: '20px',
      }}>
        <Grid container width={'100%'}>
          <Grid item xs={3}>
            <Typography variant='h6'>
                            Demo Group
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Box display={'flex'}
              flexDirection={'column'}
              alignItems={'start'}
              justifyItems={'start'}>
              <Typography variant='body1'>
                <b>Focus Areas:</b>
                                &nbsp;&nbsp;Listening, Self-Expression
              </Typography>
              <Typography variant='body1'>
                <b>Description:</b>
                                &nbsp;&nbsp;This group is a demo for
                                    testing the application with a group of users.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Button>
  );
};

GroupCard.propTypes = {
  roomName: PropTypes.string.isRequired,
  handleGroupClick: PropTypes.func.isRequired,
};

export default GroupCard;
