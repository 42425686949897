/* eslint-disable linebreak-style */
import React, {useMemo} from 'react';
import {Grid, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import ChatMessage from './chatMessage';
import logger from '../../../logger';

const chatData = [
  {icon: 'love', label: 'Coach Bot', timestamp: '2023-04-01T15:30:00.000Z',
    message: 'Positive feedback will look like this'},
  {icon: 'notify', label: 'Coach Bot', timestamp: '2023-04-01T15:30:00.000Z',
    message: 'Suggestions will look like this'},
  {icon: 'target', label: 'Talk Target', timestamp: '2023-04-01T15:30:00.000Z',
    message: 'talk targets will look like this'},
  {icon: 'alert', label: '', timestamp: '2023-04-01T15:30:00.000Z',
    message: 'Alert messages will look like this'},
];

const talkTargetTip = {
  icon: 'target', label: 'Talk Target',
  timestamp: '2023-04-01T15:30:00.000Z',
  message: 'talk targets will look like this',
};

const ChatStream = ({chatHistory, talkTargetTip}) => {
  // Use useMemo to optimize performance for large chat histories
  logger.info(`got message to stream in ${JSON.stringify(chatHistory)}`);
  const renderedChatMessages = useMemo(() => {
    const reversedChatHistory = chatHistory.slice().reverse();

    return reversedChatHistory.map((chat, index) => (
      <ChatMessage key={index} icon={chat.icon}
        message={chat.message} timestamp={chat.timestamp} label={chat.label}/>
    ));
  }, [chatHistory]);

  return (
    <Grid id='chat-stream-conversation-coach'
      container
      direction="column"
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        position: 'relative',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        maxWidth: '45vw',
        p: 2,
        overflowY: 'auto',
      }}>
      <Grid item xs={12}>
        <Typography variant='h6'>
        Conversation Coach
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {renderedChatMessages}
      </Grid>
    </Grid>
  );
};

ChatStream.propTypes = {
  chatHistory: PropTypes.arrayOf(PropTypes.object),
  talkTargetTip: PropTypes.object,
};

ChatStream.defaultProps = {
  chatHistory: chatData,
  talkTargetTip: talkTargetTip,
};

export default ChatStream;
