// Logo Component
import React from 'react';
import PropTypes from 'prop-types';
import logoImage from '../../assets/images/pslogo.svg';
/**
 * logo formatting
 * @return {JSX.Element} - Rendered component
 */
export default function Logo({width}) {
  return (

    <img id='prosocial-logo'
      src={logoImage}
      alt={'ProSocial\'s logo. The word ProSocial, '+
          'spelled out with both \'o\'s replaced with smiley face chat bubbles'}
      loading="lazy"
      style={{maxWidth: `${width}px`}}
    />

  );
}

Logo.propTypes = {
  /*
    **width of logo
    */
  width: PropTypes.number,

};

Logo.defaultProps = {
  width: 225,
};

