const DOMAIN = process.env.REACT_APP_DOMAIN || 'production';
const ENV = process.env.REACT_APP_WEBENV || 'development';


export const baseURL = () => {
  let BASE_URL = '';
  if (DOMAIN === 'development') {
    BASE_URL = 'http://localhost:8000';
  } else {
    if (ENV === 'production') {
      BASE_URL = 'https://prosocialapi.azurewebsites.net';
    } else if (ENV === 'staging') {
      BASE_URL = 'https://prosocialapi-staging.azurewebsites.net';
    } else {
      BASE_URL = 'https://prosocialapi-development.azurewebsites.net';
    }
  }
  return BASE_URL;
};

