import React from 'react';
import {Box, Grid} from '@mui/material';

import {LinkTable} from './linktable';
import ContactInfo from './contact';
import PropTypes from 'prop-types';
import img1 from '../../assets/images/orange logo.png';
import SocialMediaButtonGroup from './socials';
const links = [
  {name: 'Washington Autism Alliance', path: '/#partners'},
  {name: 'link2', path: '/#product'},
  {name: 'link3', path: '/#Our-Team'},
  {name: 'link4', path: '/about'},
];

/**
 * Desktop Footer component
 * @return {JSX.Element} - Rendered component
 */
export default function DesktopFooter(
    {companyLinks, resourcesLinks, partnersLinks, logoWidth},
) {
  return (
    <section id="footer" width="100%">
      <Box width='90%' mx='auto' my='20px'>
        <hr style={{opacity: 0.4}} />
      </Box>
      <Grid container spacing={3}
        sx={{'padding': '20px',
          'display': 'flex',
          '@media (max-width:600px)': { // equivalent to 'xs'
            flexDirection: 'column',
          },
          'justifyContent': 'stretch'}}>
        <Grid item xs={1}>
          <img
            src={img1}
            alt={'A bright orange icon of a smiley face' +
            'with a chat bubble for a head.'}
            loading="lazy"
            style={{maxWidth: `${logoWidth}px`}}
          />
        </Grid>
        <Grid item xs={8}>
          <LinkTable id='company-links' company={companyLinks}
            resources={resourcesLinks}
            partners={partnersLinks}></LinkTable>
        </Grid>
        <Grid item xs={3}>
          <ContactInfo id='contact-info'></ContactInfo>
        </Grid>
      </Grid>
      <Box width='90%'
        mx='auto'
        my='20px'
        display='flex'
        justifyContent='center'>
        <SocialMediaButtonGroup
          id='social-media-buttons'></SocialMediaButtonGroup>
      </Box>
    </section>
  );
}

DesktopFooter.propTypes = {
  /**
         * a list of key-value pairs of navigation
         *  links with the link name and path:
         *  const links = [{ name: 'Partners', path: '/#partners' },..],
         */
  companyLinks: PropTypes.array,
  /**
         * a list of key-value pairs of navigation
         *  links with the link name and path:
         *  const links = [{ name: 'Partners', path: '/#partners' },..],
         */
  resourcesLinks: PropTypes.array,
  /**
         * a list of key-value pairs of navigation
         *  links with the link name and path:
         *  const links = [{ name: 'Partners', path: '/#partners' },..],
         */
  partnersLinks: PropTypes.array,
  /**
         *
         * width of logo
         */
  logoWidth: PropTypes.number,
};

DesktopFooter.defaultProps = {
  company: links,
  resources: links,
  partners: links,
  logoWidth: 40,
};

