import React, {useEffect, useRef, useState} from 'react';
import AppHeader from '../../common/appHeader';
import DesktopFooter from '../../common/footer';
import {Box} from '@mui/material';
import AppLeftNav from '../../profile/appLeftNav';
import {useUserSession} from '../../../context/userSessionContext';
import CheckInInfo from './checkInInfo';
import updateUserSession from '../../../api/updateUserSession';
import {useNavigate} from 'react-router-dom';
/**
 * Renders the SessionReview component.
 * @return {JSX.Element} The SessionReview component.
 */
export default function CheckIn() {
  const {userSession} = useUserSession();
  const [checkInData, setCheckInData] = useState(null);
  const checkincurrent = useRef(checkInData);
  const navigate = useNavigate();

  sessionStorage.setItem('appState', 'STATE_IDLE');

  const saveCheckinInfo = (data) => {
    setCheckInData(data);
    // console.log('saving checkin info', data);
    navigate('/app');
  };

  useEffect(() => {
    if (userSession &&
        checkInData &&
        userSession.sessionDetails &&
        checkInData !== checkincurrent.current) {
      checkincurrent.current = checkInData;
      // console.log('saving checkin info to session', checkInData);
      userSession.sessionDetails.checkin = checkInData;
      updateUserSession(userSession.sessionDetails);
      debugger;
    } else {
      // console.log('no checkin data to save');
      // console.log('userSession', userSession);
      // console.log('checkInData', checkInData);
    }
  }, [checkInData]);

  return (
    <div id='UserSessionCheckInPage'
      style={{backgroundColor: '#F6F6F6',
        width: '100vw',
        height: '100vh'}}>
      <AppHeader id='contextual-header' logoWidth={150}></AppHeader>
      <Box mt={'20px'}
        mb={'20px'}
        height={'calc(100% - 200px)'}
        width={'calc(100% - 40px)'}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'flex-start'}>
        <Box
          width={'260px'}>
          <AppLeftNav />
        </Box>
        <Box id='check-in-page-save-info'
          display='flex'
          height={'100%'}
          flexDirection={'column'}
          ml={'10px'}
          width={'calc(100% - 270px)'}>
          {userSession && <CheckInInfo handleSubmit={saveCheckinInfo} />}
        </Box>
      </Box>
      <DesktopFooter id='desktop-footer'></DesktopFooter>
    </div>
  );
};
