import React, {useEffect, useState} from 'react';
import AppHeader from '../common/appHeader';
import DesktopFooter from '../common/footer';
import {Box} from '@mui/material';
import AppLeftNav from '../profile/appLeftNav';
import SessionReview from './sessionReview';
import {useParams} from 'react-router-dom';
import getSessionSummary from '../../api/getSessionSummary';
import {useUserProfile} from '../../context/userProfileContext';
import SessionHighlights from './sessionHighlights';
/**
 * Renders the SessionReview component.
 * @return {JSX.Element} The SessionReview component.
 */
export default function SessionSummary() {
  const {userProfile} = useUserProfile();
  const {sessionId} = useParams();
  const [sessionData, setSessionData] = useState(null);

  const setUpSession = async () => {
    console.log('setting up session');
    const response = await getSessionSummary(userProfile.user_id, sessionId);
    setSessionData(response);
  };

  useEffect(() => {
    if (sessionId && userProfile) {
      userProfile.user_id && setUpSession();
    }
  }, [sessionId, userProfile]);


  return (
    <div id='user-session-review-home'
      style={{backgroundColor: '#F6F6F6',
        width: '100vw',
        height: '100vh'}}>
      <AppHeader logoWidth={150}></AppHeader>
      <Box mt={'20px'}
        mb={'20px'}
        height={'calc(100% - 200px)'}
        width={'calc(100% - 40px)'}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'flex-start'}>
        <Box
          width={'260px'}>
          <AppLeftNav />
        </Box>
        <Box
          display='flex'
          height={'100%'}
          flexDirection={'column'}
          ml={'10px'}
          width={'calc(100% - 270px)'}>
          {sessionData && <SessionReview></SessionReview>}
          <Box height={'20px'}></Box>
          {sessionData && <SessionHighlights />}
        </Box>
      </Box>
      <DesktopFooter></DesktopFooter>
    </div>
  );
};
