import React, {useState} from 'react';
import MoodMeterTable from './moodMeter';
import {Box, Typography, TextField, Button} from '@mui/material';
import PropTypes from 'prop-types';
import {useUserProfile} from '../../../context/userProfileContext';

/**
 * Renders the CheckIn component.
 * @param {Function} handleSubmit - The function to handle the form submission.
 * @return {JSX.Element} The rendered CheckIn component.
 */
const CheckInInfo = ({handleSubmit}) => {
  const [inputValue, setInputValue] = useState('');
  const {userProfile} = useUserProfile();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const [mood, setMood] = useState({label: null, emoji: null});
  const handleMoodClick = (data) =>{
    data && console.log(`handling mood click for ${data.emoji}`);
    setMood(data);
  };

  const handleSubmitClick = () => {
    handleSubmit({emoji_label: mood.label,
      emoji: mood.emoji, comments: inputValue});
  };

  return (
    <Box id='check-in' p={2} width={'90%'}
      overflowY='auto'>
      <Typography variant="h6" sx={{
        textAlign: 'center', width: '100%'}}>
          Hello {userProfile &&
            userProfile.profile && userProfile.profile.displayName}!
         Before you join, let&apos;s do a quick check-in. </Typography>
      <Typography variant="body1" sx={{
        textAlign: 'left', width: '100%'}}>
                        How are you feeling today? <p></p>
      </Typography>
      <MoodMeterTable id='mood-meter-table'
        handleMoodClick={handleMoodClick}></MoodMeterTable>
      <p></p>
      <Typography variant="body1" sx={{
        textAlign: 'left', width: '100%'}}>
                        Is there anything on your mind that
                         you want to share with the group? <p></p>
      </Typography>
      <Box mt={2} sx={{width: '100%', textAlign: 'center'}}>
        <TextField
          label="Check In"
          variant="outlined"
          value={inputValue}
          multiline
          rows={4}
          onChange={handleInputChange}
          sx={{marginRight: 2,
            width: '100%'}}
        />
        <p></p>
        <Button id='check-in-submit'
          variant="contained"
          color="primary"
          onClick={handleSubmitClick}
        >
                    I&apos;m Ready!
        </Button>
      </Box>
    </Box>
  );
};

CheckInInfo.propTypes = {
  selectedId: PropTypes.string,
  handleSubmit: PropTypes.func,
};

CheckInInfo.defaultProps = {
  selectedId: 'Annie',
  handleSubmit: () => {},
};
export default CheckInInfo;

