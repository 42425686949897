import axios from 'axios';
import {baseURL} from './setEnvironment';

export const saveUserProfile = async (data, userId) => {
  try {
    console.log('saving user profile with data: ' + JSON.stringify(data));
    const requestConfig = {
      url: baseURL()+`/${userId}/update-user`,
      method: 'POST',
      data: data,
      headers: {
        'content-type': 'application/json',
      }};

    const response = await axios(requestConfig);
    console.log('response from saveUserProfile: ' + JSON.stringify(response));
    if (response.status === 200) {
      return response.data.status;
    } else {
      console.log(response.data.status, response);
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export default saveUserProfile;

