import React from 'react';
import {
  useDaily,
  useLocalSessionId,
  useVideoTrack,
  useAudioTrack,
} from '@daily-co/daily-react';
import {Box, IconButton, Tooltip} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PanToolIcon from '@mui/icons-material/PanTool';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PropTypes from 'prop-types';

const VideoControls = () => {
  const daily = useDaily();

  const localParticipant = useLocalSessionId();
  const localVideo = useVideoTrack(localParticipant);
  const localAudio = useAudioTrack(localParticipant);
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const toggleVideo = () => {
    daily.setLocalVideo(localVideo.isOff);
  };

  const toggleAudio = ()=> {
    daily.setLocalAudio(localAudio.isOff);
  };

  const leave = () => {
    daily.leave();
  };

  return (
    <Box id='video-controls'
      display="flex"
      alignItems="center"
      justifyContent="end"
      bgcolor="#111111"
      height='5%'
    >
      <Tooltip title="Microphone" sx={{
        color: mutedAudio ? 'secondary' : 'primary.contrastText'}}
      onClick={toggleAudio}>
        <IconButton id='audio-toggle-button'
          color={mutedAudio ? 'secondary' : 'contrastText'}>
          {mutedAudio ? <MicOffIcon /> : <MicIcon />}
        </IconButton>
      </Tooltip>

      <Tooltip title="Camera" sx={{
        color: mutedVideo ? 'secondary' : 'primary.contrastText'}}
      onClick={toggleVideo}>
        <IconButton id='camera-toggle-button'
          color={mutedVideo ? 'secondary' : 'contrastText'}>
          {mutedVideo ? <VideocamOffIcon /> : <VideocamIcon />}
        </IconButton>
      </Tooltip>

      <Tooltip title="Raise Hand">
        <IconButton color="primary">
          <PanToolIcon style={{color: 'white'}} />
        </IconButton>
      </Tooltip>

      <Tooltip title="React">
        <IconButton color="primary">
          <EmojiEmotionsIcon id='reactions-toggle-button'
            style={{color: 'white'}} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Leave">
        <IconButton id='exit-icon-button' color="primary" onClick={leave} sx={{
          '&:hover': {
            color: (theme) => theme.palette.secondary.main,
          },
        }}>
          <ExitToAppIcon style={{color: 'white'}} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

VideoControls.propTypes = {
  leaveCall: PropTypes.func,
};

export default VideoControls;
