/* eslint-disable linebreak-style */
import React, {useState, useRef, useEffect} from 'react';
import {Box, Typography, Grid, Paper, Popover} from '@mui/material';
import CircleWithCheck from '../../common/checkMark';
import PropTypes from 'prop-types';
import updateUserSession from '../../../api/updateUserSession';
import {useUserSession} from '../../../context/userSessionContext';

const TimelineAgenda = ({timelineData}) => {
  // State for managing modal visibility and content
  const [modalContent, setModalContent] =
   useState({explanation: '', title: ''});
  const {userSession, setUserSession} = useUserSession();
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const firstCircleRef = useRef(null);
  const circleParentRef = useRef(null);
  const [boxLocation, setBoxLocation] = useState({top: 0, left: 0, right: 0});
  const activeTarget = useRef(selectedTarget);
  const sessionRef = useRef(userSession);

  console.log(`user session in timeline agenda:`, userSession);

  // Function to han  dle mouse enter event
  const handleMouseEnter = (event, e, t) => {
    setAnchorEl(event.currentTarget);
    setModalContent({} = {explanation: e, title: t});
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setModalContent({explanation: '', title: ''});
    setAnchorEl(null);
  };

  const getPosition = () => {
    if (firstCircleRef.current && circleParentRef.current) {
      const childRect = firstCircleRef.current.getBoundingClientRect();
      const parentRect = circleParentRef.current.getBoundingClientRect();
      setBoxLocation({
        top: childRect.top - parentRect.top + childRect.height / 2,
        left: childRect.left - parentRect.left + childRect.width / 2,
        right: childRect.right - parentRect.left - childRect.width / 2,
      });
    }
  };

  /**
   * This useEffect hook is responsible for setting up and tearing down an event listener for window resize events.
   *
   * - When the component mounts, it calls the `getPosition()` function to get the initial position of an element.
   * - It then adds a `resize` event listener to the window, which calls the `getPosition()` function whenever the window is resized.
   * - When the component unmounts, it removes the `resize` event listener to clean up the event listener.
   * - The hook is dependent on no other values, so it will only run on mount and unmount.
   */
  useEffect(() => {
    getPosition();

    const handleResize = () => {
      getPosition();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const setActiveTarget = (target) => {
    console.log('setting active target', target);
    if (selectedTarget !== target) {
      setSelectedTarget(target);
    }
  };

  /**
   * This useEffect hook is responsible for updating the `userSession.sessionDetails.targets` array when the `selectedTarget` value changes.
   *
   * - It checks if the `userSession` object and `userSession.sessionDetails.targets` array exist.
   * - If they do, it maps over the `userSession.sessionDetails.targets` array and sets the `isActive` property to `true` for the target that matches the `selectedTarget` value, and `false` for all others.
   * - It then updates the `userSession` object with the modified `targets` array.
   * - The hook is dependent on the `selectedTarget` and `userSession` values.
   */
  useEffect(() => {
    if (activeTarget.current !== selectedTarget && userSession && userSession.sessionDetails &&
       userSession.sessionDetails.targets) {
      activeTarget.current = selectedTarget;

      const updatedTargets = userSession.sessionDetails.targets.map((item) => ({
        ...item,
        isActive: item.label === selectedTarget,
      }));

      console.log('updated targets', updatedTargets);

      const updatedSessionDetails = {
        ...userSession.sessionDetails,
        targets: updatedTargets,
      };
      setUserSession(userSession.sessionRoom, updatedSessionDetails);
      }
  }, [selectedTarget, userSession]);

  /**
   * This useEffect hook is responsible for updating the `targetData` state and the `currentUserSession.current` reference based on changes in the `userSession` object.
   *
   * - If the `userSession.sessionDetails.targets` property changes, it updates the `targetData` state with the new targets.
   * - If the `userSession` object changes, it updates the `currentUserSession.current` reference and logs the updated user session.
   * - The hook is dependent on the `userSession` and `targetData` values.
   */
  useEffect(() => {
    if (sessionRef.current!== userSession) {
      updateUserSession(userSession);
      sessionRef.current = userSession;
      }
  }, [userSession]);

  /**
   * This useEffect hook is responsible for updating the `activeTarget` state based on changes in the `timelineData` array.
   * - timeline data is recieved from the event source for the session.
   * - If the `timelineData` array is not null and has at least one item, it loops through the array to find the first item with `isActive` set to true.
   * - When an active target is found, it sets the `activeTarget` state to the label of that target.
   * - The hook is dependent on the `timelineData` array.
   */
  useEffect(() => {
    if (timelineData && timelineData.length > 0) {
      console.log(`triggering effect on timeline data with value: 
        ${timelineData}`);
      for (const item of timelineData) {
        if (item.isActive) {
          console.log('found active target', item.label);
          setActiveTarget(item.label);
          break;
        }
      }
    }
  }, [timelineData]);

  return (
    <Box id='timeline-agenda' ref={circleParentRef} position="relative"
      top='10px' className="timeline-agenda-container"
      height={'100px'}>
      <Typography variant="h6" style={{
        textAlign: 'center', width: '100%'}}>
          Talk Targets
      </Typography>
      {/* Timeline Line */}
      <Box
        position="absolute"
        top={boxLocation.top + 'px'}
        left={boxLocation.left + 'px'}
        right={boxLocation.right + 'px'}
        height="4px"
        bgcolor="#515abb"
        zIndex="1"
      />

      <Grid container direction="row" alignItems="start">
        {userSession
        && userSession.sessionDetails
        && userSession.sessionDetails.targets
        && userSession.sessionDetails.targets.map((item, index) => (
          <Grid item xs key={index} style={{position: 'relative', zIndex: 2}}>
            <Box>
              <Grid id='timeline-checkbox-button' container
                direction="column"
                onMouseEnter={(e) =>
                  handleMouseEnter(e, item.explanation, item.label)}
                onMouseLeave={handleMouseLeave}
                onClick={() => setActiveTarget(item.label)}
                alignItems="center">
                {/* Checkbox */}
                <Grid item style={{alignSelf: 'center'}}>
                  <CircleWithCheck
                    ref={index === 0 ? firstCircleRef : null}
                    checked={item.checkstate}
                    selected={selectedTarget===item.label? true : false} />
                </Grid>

                {/* Label */}
                <Grid item style={{alignSelf: 'center',
                  width: '100%', height: '50px'}}>

                  <Typography
                    variant="caption"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      // transform: 'rotate(-35deg)',
                      // transformOrigin: 'right top',
                      width: '100%',
                      padding: '5px',
                      whiteSpace: 'normal',
                      textAlign: 'center',
                      overflowWrap: 'break-word',
                      // marginTop: '-15px',

                    }}
                  >

                    {item.label}

                  </Typography>

                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Modal for displaying explanation */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{pointerEvents: 'none'}}
        disableRestoreFocus
        onClose={handleMouseLeave}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalContent.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{mt: 2}}>
            {modalContent.explanation}
          </Typography>
        </Paper>
      </Popover>
    </Box>
  );
};

// Separate default variable for checkData
const checkDataDefault = [
  {label: 'Default Step 1',
    checkstate: true, explanation: 'This is the first step'},
  {label: 'Default Step 2',
    checkstate: true, explanation: 'This is the second step'},
  {label: 'Default Step 3',
    checkstate: false, explanation: 'This is the third step'},
  {label: 'Default Step 4',
    checkstate: false, explanation: 'This is the fourth step'},
  {label: 'Default Step 5', checkstate: false}, // No explanation,
];

TimelineAgenda.propTypes = {
  timelineData: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        checkstate: PropTypes.bool.isRequired,
        explanation: PropTypes.string,
      }),
  ).isRequired,
};

TimelineAgenda.defaultProps = {
  timelineData: checkDataDefault,
};

export default TimelineAgenda;
