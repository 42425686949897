/* eslint-disable max-len */
import React, {useState} from 'react';
import listen from '../../assets/images/FC-Listen.svg';
import expression from '../../assets/images/FC-Self-Expression-4.svg';
import saveUserProfile from '../../api/saveProfile';
import {useUserProfile} from '../../context/userProfileContext';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

/**
 * This is a starter form component.
 * @return {JSX.Element} the form component
 */
function StarterForm() {
  const {userProfile, setUserProfile} = useUserProfile();
  userProfile &&
    console.log('userProfile from context: ' + JSON.stringify(userProfile));
  const defaultDisplayName = 'Default: ' +
   (userProfile.profile && userProfile.profile.name);
  const [formValues, setFormValues] = useState({
    displayName: '',
    age: '',
    target: '',
    strengths: '',
    help_requested: '',
    sentiment: '',
  });

  const [selectedTarget, setSelectedTarget] = useState(null);

  const targets = {
    share: {
      label: 'share',
      explanation: 'Share a new idea or opinion with the group.',
      checkstate: false,
    },
    validate: {
      label: 'validate',
      explanation: 'Show appreciation or agreement with someone else\'s idea',
      checkstate: false,
    },
  };

  const shareLabel =
    <Box m={2} display={'flex'} flexDirection={'row'}>
      <img src={listen} alt="listen" style={{width: '50px'}} />
      <Box ml={2}>
        <Typography>Listening skills describe how you understand
             and pay attention to other people.
        </Typography>
        <br></br>
        <Typography variant={'body1'}>
          <span><b>Talk Target: &nbsp;</b></span>
          <span>Show appreciation for or
        agreement with someone else&apos;s idea.</span>
        </Typography>
      </Box>
    </Box>;

  const validateLabel =
  <Box m={2} display={'flex'} flexDirection={'row'}>
    <img src={expression}
      alt="Self-Expression" style={{width: '50px'}} />
    <Box ml={2}>
      <Typography>Self-expression is how you share your own ideas,
         feelings, and thoughts.
      </Typography>
      <br></br>
      <Typography variant={'body1'}>
        <span><b>Talk Target: &nbsp;</b></span>
        <span>Share a new idea or opinion with the group.</span>
      </Typography>
    </Box>
  </Box>;

  const handleChange = (event) => {
    const {name, value} = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === 'target') {
      setSelectedTarget(targets[value]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (userProfile) {
      // console.log('userProfile profile: ' + JSON.stringify(userProfile));
      const userId = userProfile.profile.user_id? userProfile.profile.user_id : null;
      const email = userProfile.profile.email? userProfile.profile.email : null;
      const name = userProfile.profile.name? userProfile.profile.name : null;
      const userData = {
        user_id: userId,
        id: userId,
        email: email,
        name: name,
        displayName: formValues.displayName || name,
        age: formValues.age,
        help_requested: formValues.help_requested,
        strengths: formValues.strengths,
        sentiment: formValues.sentiment,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        targets: [
          selectedTarget,
        ],
      };
      console.log('saving profile for user: ' + userId);
      try {
        const response = await saveUserProfile(userData, userId);
        console.log('response from saveUserProfile: ' + response);
        setUserProfile(userData);
        window.location.reload();
      } catch (error) {
        console.log('error saving profile: ' + error);
      }
    }
  };

  return (
    <Box id='starter-form'
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '80%',
        margin: 'auto',
        padding: 3,
      }}
    >

      <TextField
        label="Display Name"
        name="displayName"
        helperText={defaultDisplayName}
        value={formValues.displayName}
        onChange={handleChange}
        fullWidth
        required
      />

      <TextField
        label="Age"
        name="age"
        type="number"
        value={formValues.age}
        onChange={handleChange}
        fullWidth
        required
      />

      <TextField
        label="What are your social strengths?"
        name="strengths"
        helperText="What do you like about yourself?  What makes you a great friend?"
        value={formValues.strengths}
        onChange={handleChange}
        fullWidth
        required
      />
      <TextField
        label="What do you need help with?"
        name="help_requested"
        helperText="How do you think you can improve socially?  What are you working on?"
        value={formValues.help_requested}
        onChange={handleChange}
        fullWidth
        required
      />

      <TextField
        label="How are you feeling about this tool?"
        name="sentiment"
        value={formValues.sentiment}
        onChange={handleChange}
        fullWidth
        required
      />
      <p> </p>
      <FormControl component="fieldset">
        <FormLabel component="legend">Choose a Starting Focus Area</FormLabel>
        <RadioGroup
          name="target"
          value={formValues.target}
          onChange={handleChange}
        >
          <br></br>
          <Typography variant={'body1'}><b>Listening</b></Typography>
          <FormControlLabel
            m={1}
            value='share'
            control={<Radio />}
            label={shareLabel}
          />
          <br></br>
          <Typography variant={'body1'}><b>Self-Expression</b></Typography>
          <FormControlLabel
            value='validate'
            control={<Radio />}
            label={validateLabel}
          />
        </RadioGroup>
      </FormControl>

      <Button variant="contained" type="submit" sx={{width: '200px'}}>
                Submit
      </Button>
    </Box>
  );
}

export default StarterForm;
