import React from 'react';
import {Grid, Typography, Box, List, ListItem} from '@mui/material';
import PropTypes from 'prop-types';
import {modelData} from '../../../models/product';

const ModelCard = ({data}) => (

  <Grid container spacing={2}>
    {data.map((data, index) => (
      <React.Fragment key={index}>
        <Box padding={2}>
          <Grid container spacing={3}>
            <Grid item xs={4} style={{paddingRight: '1em'}}>
              <Box display="flex"
                justifyContent="flex-end"
                flexDirection="column">
                <Typography variant="subtitle1" align="left">
                  {data.model}
                </Typography>
                <img src={data.image}
                  alt={data.model}
                  style={{maxWidth: '150px'}} />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">
                {data.description}
              </Typography>
              <List>
                {data.methods.map((method, index) => (

                  <ListItem key={index}>
                    <Typography variant="body2">
                      {method}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    ))};
  </Grid>
);

ModelCard.propTypes = {

  data: PropTypes.arrayOf({
    model: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    methods: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

ModelCard.defaultProps = {
  data: modelData,
};

export default ModelCard;
