import axios from 'axios';
import {baseURL} from './setEnvironment';

export const getActiveSession= async (userId) => {
  try {
    const requestConfig = {
      url: baseURL()+`/users/${userId}/find-active-session`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      }};

    const response = await axios(requestConfig);
    console.log('response from get active session: ' +
    JSON.stringify(response.data));

    if (response.status === 204) {
      return null;
    } else if (response.status === 200) {
      return response.data;
    } else {
      console.log(response.data.status, response);
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export default getActiveSession;
