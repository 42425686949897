/* eslint-disable max-len */
// models/dataModel.js

import mary from '../assets/images/mary.png';
import lara from '../assets/images/lara.png';
import dan from '../assets/images/innov8_dan.png';

const peopleData = [
  {
    image: mary,
    desc: 'an image of a woman in a green sweater with long brown hair against a natural background, smiling',
    name: 'Mary Schwaber',
    title: 'Co-Founder and CEO',
    text: 'Mary is a 15-year Microsoft veteran most recently as a Principal Data Scientist in Microsoft Education.  She also has a Master\'s degree in teaching and is excited to use her passion for education technology to bring people together in new ways.',
    links: {
      linkedin: 'https://www.linkedin.com/in/mary-schwaber-113a115/',
      medium: 'https://medium.com/@mary.schwaber',
      email: 'mailto:mary@prosocial.care',
    },
  },
  {image: lara,
    desc: 'an image of a woman in a green sweater with long brown hair against a natural background, smiling',
    name: 'Lara Martin',
    title: 'Co-Founder and CTO',
    text: 'Lara has worked in the technology industry for over a decade in a variety of roles. She is passionate about ethical and human-centered technology and exploring how technology can be used to enhance and enrich the human experience rather than supersede it.',
    links: {
      linkedin: 'https://www.linkedin.com/in/lara-martin-66abb946/',
      email: 'mailto:lara@prosocial.care',
    }},

  {image: dan,
    desc: 'a black and white image of a main with brown hair and a broad smile, wearing a suit jacket',
    name: 'Dan Stachelski',
    title: 'Advisor',
    text: 'Dan is a Speech Language Pathologist and serial entrepreneur that has built, merged, acquired, and sold companies over his career. Over the last 20 years he has been building ICAN, a leader in the diagnosis and treatment of children and families affected by autism and other developmental challenges. He also holds a bachelor’s degree from Western Washington University and a masters from Southern Illinois University.',
    links: {

      linkedin: 'https://www.linkedin.com/in/dan-stachelski-49976610/',
      website: 'https://innov8.care/',

    },
  },
];

export default peopleData;
