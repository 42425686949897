/* eslint-disable max-len */
import rc from '../assets/images/friendscoffee.png';
import s from '../assets/images/friends-selfie.png';
import v from '../assets/images/friendsvideogames.png';


const valuesData = [
  {
    image: rc,
    header: 'Real Connection',
    text: 'ProSocial is about forming real connections with real people. Our most ' +
      'important success metric is whether or not friendships formed on our platform generalize beyond guided social groups.',
  },
  {
    image: s,
    header: 'Everyone has a tribe',
    text: 'There are a lot of people in this world. Whether you\'re into board games, calculus, or zookeeping, there\'s someone that thinks you are awesome, just the way you are. We want to help you find them.',
  },
  {
    image: v,
    header: 'Agency, Not Assimilation',
    text: 'Our differences are what make us beautiful. We want to help you be the best version of yourself, not make you into someone you are not. You decide what you want to work on, and we will support you in those goals.',
  },
];

export default valuesData;
