import React from 'react';
import {Box, Modal, Typography, Button} from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Renders a modal dialog that prompts the user to join an active session.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Indicates whether
 * the modal should be displayed.
 * @param {function} props.closeModal - A function to close the modal.
 * @return {JSX.Element} - The rendered modal dialog.
 */
export default function JoinActiveSessionDialog({
  showModal, closeModal, goToActiveSession,
}) {
  return (
    <Modal id='join-active-sesssion-dialog'
      open={showModal}
      onClose={closeModal}
      aria-labelledby="Active Session Check"
      aria-describedby="An active session has been found.
        Checking for join"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          You have an active session currently in progress.
        </Typography>
        <Typography sx={{mt: 2}}>
          Do you want to join that session now?
        </Typography>
        <Box mt={3}>
          <Button id='join-active-session'
            variant="contained"
            color="primary"
            onClick={goToActiveSession}
            sx={{mr: 2}}
          >
            Yes, Join session
          </Button>
          <Button id='return-to-profile'
            variant="outlined" onClick={closeModal}>
            No, Return to my profile
          </Button>
        </Box>
      </Box>
    </Modal>);
};

JoinActiveSessionDialog.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  goToActiveSession: PropTypes.func.isRequired,
};
