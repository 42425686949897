import React from 'react';
import {Paper, Typography} from '@mui/material';

const ContactInfo = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        backgroundColor: 'grey.300',
        borderRadius: 6,
        textAlign: 'right',
        maxWidth: 300,
        minWidth: 200,
        minHeight: 150,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
      }}
    >
      <Typography variant="body2" component="div" sx={{fontWeight: 'bold'}}>
        +1-800-888-9697 <br />
        info@prosocial.care
      </Typography>

    </Paper>
  );
};

export default ContactInfo;
