import React from 'react';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';

export const WidgetBarItem = ({value, label}) => {
  return (
    <Box
      sx={{
        bgcolor: '#C5CAE9', // Shade color for the box
        boxShadow: 3, // Box shadow for a shaded effect
        borderRadius: '16px', // Rounded corners
        p: 1, // Padding inside the box
        display: 'flex', // Use flexbox
        justifyContent: 'center', // Center align horizontally
        alignItems: 'center', // Center align vertically
        height: '80px', // Set a fixed height
        width: '120px', // Set a fixed width
        m: 3,
      }}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="body1" textAlign="center">
        {value}
      </Typography>
      <Typography variant="body3" textAlign="center">
        {label}
      </Typography>
    </Box>
  );
};

WidgetBarItem.propTypes = {

  value: PropTypes.string,

  label: PropTypes.string,

};

WidgetBarItem.defaultProps = {
  value: '33.4',
  label: 'turn time',
};
