// emailAPI.js
import axios from 'axios';
import {baseURL} from './setEnvironment';

export const sendEmail = async (formData) => {
  try {
    console.log('Env: ' +
    process.env.REACT_APP_WEBENV +
    ' Domain: ' + process.env.REACT_APP_DOMAIN);
    console.log('running email API with :' +
    JSON.stringify(formData) + ' for ' + baseURL());
    const response = await axios.post(baseURL()+'/send-mail', formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.status === 'Success') {
      return 'Thank you! Your submission has been received!';
    } else {
      console.log(response.data.status, response);
      return 'Oops! Something went wrong while submitting the form.';
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.detail) {
      console.error(error.response.data.detail);
    }
    return 'Oops! Something went wrong while submitting the form.';
  }
};
