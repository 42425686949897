import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,

} from '@mui/material';
import {Link} from 'react-router-dom';


export const DefaultButton = ({
  primary,
  variantName,
  label,
  linkPage,
  color,
  size,
  startIcon,
  endIcon,
  disabled,
  hashLink,
  ...props
}) => {
  const linkTo = hashLink ? `#${hashLink}` : linkPage;
  return (

    <Button
      variant={primary ? 'contained' : variantName}
      component={Link}
      to={linkTo}
      color={primary ? 'primary' : color}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      {...props}
    >
      {label}
    </Button>

  );
};

DefaultButton.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool.isRequired,
  /**
   * hash link for in page linking
   */
  hashLink: PropTypes.string,

  /**
   * button style
   */
  variantName: PropTypes.oneOf(['contained', 'outlined', 'text']),
  /**
   * button link ../path
   */
  linkPage: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * The color of the component. It supports
   * both default and custom theme colors
   */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
  ]),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * icon before text.  Add icon names ie <DeleteIcon /> from
   * @mui/icons-material/ (remember to import)
   */
  startIcon: PropTypes.node,
  /**
   * icon after text
   */
  endIcon: PropTypes.node,
  /**
   * button inactive
   */
  disabled: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

DefaultButton.defaultProps = {
  primary: true,
  variantName: 'contained',
  color: 'primary',
  size: 'medium',
  label: 'button',
  disabled: false,
  linkPage: '/',
  onClick: undefined,
};


