
import React from 'react';
import {Link, Typography} from '@mui/material';

const VideoError = () => {
  return (
    <div>
      <Typography variant="h1">Error</Typography>
      <Typography variant="body1">
  Room could not be created. Please check your local
  configuration in `api.js`. For more information, check out
  the{' '}
        <Link to="https://github.com/daily-demos/call-object-react-daily-hooks/blob/main/README.md">
    docs
        </Link>
  .
      </Typography>
    </div>);
};

export default VideoError;
