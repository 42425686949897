import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItemButton,
  ListSubheader,
  Box,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {Divider} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {featureLinks} from '../../../models/navlinks';
import {HashLink} from 'react-router-hash-link';
// default links for nav menus
const defaultLinks = featureLinks;

// Hamburger Menu drawer Component
export const NavigationMenuDrawer = ({
  title,
  links,
  color,
  selectedBg,
  selectedText,
  width,
  textColor,
  ...props
}) => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Box

      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: {
          xs: theme.palette.primary.main,
          sm: 'white',
        },
        color: {
          xs: theme.palette.primary.contrastText,
          sm: 'black',
        },
        width: width,
      }}
    >
      <Box sx=
        {{padding: '16px',
          display: {xs: 'block', sm: 'none'},
        }}>
        <MenuIcon />
      </Box>
      <List>
        <ListSubheader sx={{
          backgroundColor: 'inherit',
          color: 'inherit',
          fontSize: '1.2em',
        }}>
          {title}
        </ListSubheader>
        <Divider sx={{
          width: '95%',
          margin: '0 auto',
          borderColor: 'lightgrey',
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        }} />
        {links.map((link, index) => (
          <ListItemButton
            key={index}
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
            component={HashLink}
            to={link.path}
            sx={{
              'backgroundColor': selectedIndex === index ?
              theme.palette.primary.light : 'inherit',
              '&.Mui-selected': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.darkText,
              },
            }}
          >
            <ListItemText primary={link.name} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

NavigationMenuDrawer.propTypes = {
  /**
   * Non-Navigable, optional, title of menu
   */
  title: PropTypes.string,
  /**
   * a list of key-value pairs of navigation links with the link name and path:
   *  const links = [{ name: 'Partners', path: '/#partners' },..],
   */
  links: PropTypes.array,
  /**
   * Indicates if a list item is selected or not
   */
  selected: PropTypes.bool,
  /**
   * background color of unselected nav drawer menu items
   */
  color: PropTypes.string,
  /**
   * background color of selected nav drawer menu items
   */
  selectedBg: PropTypes.string,
  /**
   * background text color of selected nav drawer menu items
   */
  selectedText: PropTypes.string,
  /**
   * Total width of drawer
   */
  width: PropTypes.number,
  /**
   * background text color of unselected nav drawer menu items
   */
  textColor: PropTypes.string,
};

NavigationMenuDrawer.defaultProps = {
  title: 'Nav Drawer',
  links: defaultLinks,
  color: 'primary',
  selectedBg: 'primary.light',
  selectedText: 'primary.contrastText',
  width: 200,
  textColor: 'primary.contrastText',
};
