/* eslint-disable max-len */
// src/components/common/authentication-guard.js
import React from 'react';
import PropTypes from 'prop-types';
import {withAuthenticationRequired} from '@auth0/auth0-react';

const AuthenticationGuard = ({component: Component}) => {

  const AuthenticatedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <div>Loading ...</div>,
  });

  return <AuthenticatedComponent id="authentication-guard" />;
};

AuthenticationGuard.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthenticationGuard;
