import React from 'react';
import TwoRowGrid from '../cards/peopleCard';
import Grid from '@mui/material/Grid';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import peopleData from '../../../models/people';

/**
 * team component
 * @return {JSX.Element} - Rendered component
 */
export default function TeamGrid({slides}) {
  return (
    <section id='team'>
      <Grid container spacing={3}>


        {slides.map((slide, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box>
              <TwoRowGrid imgData={slide} showSocials='true'></TwoRowGrid>
            </Box>
          </Grid>
        ))}

      </Grid>
    </section>
  );
};

TeamGrid.propTypes = {

  /** *
     * a list of people information
     */
  slides: PropTypes.shape([{
    image: PropTypes.object.isRequired,
    desc: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string, // optional
    text: PropTypes.string.isRequired,
    links: PropTypes.shape({
      facebook: PropTypes.string,
      twitter: PropTypes.string,
      instagram: PropTypes.string,
      linkedin: PropTypes.string,
      medium: PropTypes.string,
      github: PropTypes.string,
      email: PropTypes.string,
    }).isRequired},
  ]),
};

TeamGrid.defaultProps = {
  slides: peopleData,
};

