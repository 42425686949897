import React from 'react';
import {Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {DefaultButton} from '../navigation/button';
import {styled} from '@mui/material/styles';

const WhiteTextSpan = styled('span')({
  color: '#ffffff',
});

/**
 * Hero Text component
 * @return {JSX.Element} - Rendered component
 */
export default function Hero() {
  const theme = useTheme();

  return (
    <section id="hero">
      <Box
        width='100vw'
        sx={{
          background: `linear-gradient(white, ${theme.palette.secondary.main})`,
          padding: theme.spacing(8, 0), // Add vertical padding for spacing
          textAlign: 'center', // Centralize text content
        }}
      >
        <Typography variant="h1">Find your people.</Typography>
        <br />
        <Box sx={{
          padding: '20px',
          margin: {sm: '0px 20px', md: '0px 200px'},
        }}>
          <Typography variant="h5" >
            Prosocial is a virtual social coach and facilitator,
            helping all people create and sustain
            <WhiteTextSpan> meaningful, lasting friendships.</WhiteTextSpan>
            Interested in trying it out?</Typography>
          <br /></Box>
        <DefaultButton
          label="Join our waitlist"
          linkPage="/waitlist"></DefaultButton>
      </Box>
    </section>
  );
}
