
import React, {useEffect, useState} from 'react';
import {Box, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {useDaily, useDailyEvent} from '@daily-co/daily-react';
import CircularProgress from '@mui/material/CircularProgress';

const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_IN_CALL = 'STATE_IN_CALL';

const VideoJoin = ({setAppState, sendError}) => {
  const [isJoining, setIsJoining] = useState(false);
  const [joined, setJoined] = useState(false);
  const daily = useDaily();

  useDailyEvent('joining-meeting', () => setIsJoining(true));
  useDailyEvent('joined-meeting', () => setJoined(true));
  useDailyEvent('error', sendError);

  useEffect(() => {
  if (isJoining)  { 
    console.log('joining call');
  }
  if (joined) {
    setAppState(STATE_JOINED);
    console.log('joined call');
  }
  }, [isJoining, joined]);

  const start = (event) => {
    event.preventDefault();
    console.log('starting call');
    daily && daily.join();
  };


  return (
    <Box id='video-pre-join'
      display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography variant="h1" mt={5} textAlign='center' sx={{
        fontSize: {xs: '1rem', sm: '1.2rem', m: '1.5rem'}}}>
      CALL DEMO <p></p>
      </Typography>
      {isJoining && <CircularProgress id='loading-circle'
        sx={{color: 'black'}} />}
      {!isJoining && <div> <Typography textAlign='center'
        variant="body1"
        sx={{
          color: 'black',
          fontSize: {xs: '.7rem', sm: '1rem'},
        }}>
        Tap the button below to join
      </Typography>
      <Box width={'100%'} display='flex'
        mt={5} justifyContent={'center'}>
        {daily &&
        <Button id='join-call-button' variant="contained"
          onClick={start} disabled={isJoining}>
      JOIN
        </Button>}
      </Box>
      <Box width={'100%'} display='flex'
        m={5} justifyContent={'center'}>
        <Typography variant="body1" textAlign="center" sx={{
          color: '#f44336',
          fontSize: {xs: '.7rem', sm: '1rem'}}}>
      IMPORTANT: Select “Allow” to use your camera and mic for
      this call if prompted.
        </Typography>
      </Box>
      </div>
      }
    </Box>);
};

VideoJoin.propTypes ={
  setAppState: PropTypes.any,
  sendError: PropTypes.any,
};

VideoJoin.defaultProps = {

};

export default VideoJoin;
