import React from 'react';
import {Grid, Typography, Box} from '@mui/material';
import PropTypes from 'prop-types';
import {widgetsData} from '../../../models/product';

const WidgetCard = ({data}) => (
  <Grid container spacing={2}>
    {data.map((data, index) => (
      <React.Fragment key={index}>
        <Grid item xs={6} style={{paddingRight: '1em'}}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            padding="2"
            height="200px"
          >
            <Box display="flex" alignContent="flex-start" height="100px">
              <img
                src={data.image}
                alt={data.description}
                style={{maxWidth: '200px'}}
              />
            </Box>
            <Typography variant="subtitle1" align="left">
              {data.widget}
            </Typography>
            <Typography variant="body2" align="left">
              {data.description}
            </Typography>
          </Box>
        </Grid>
      </React.Fragment>
    ))}
  </Grid>
);

WidgetCard.propTypes = {
  data: PropTypes.arrayOf(
      PropTypes.shape({
        widget: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
  ).isRequired,
};

WidgetCard.defaultProps = {
  data: widgetsData,
};

export default WidgetCard;
