/* eslint-disable max-len */
import React from 'react';
import {Box,
  Typography,
  List,
  ListItem,
  ListItemText} from '@mui/material';

import PropTypes from 'prop-types';
import {skillsData} from '../../models/product';

const coachingData = [
  {
    category: 'Self-Expression',
    highlights: [
      'Great job validating others!',
      'When you said: “That thing you said”, it helped others understand blah blah',
    ],
    suggestions: [
      'do this suggestion',
      'and this one',
      'and also this',
    ],
  },
  {
    category: 'Engaging Others',
    highlights: [
      'Great job validating others!',
      'When you said: “That thing you said”, it helped others understand blah blah',
    ],
    suggestions: [
      'do this suggestion',
      'and this one',
      'and also this',
    ],
  },
  {
    category: 'Engaging Others',
    highlights: [
      'Great job validating others!',
      'When you said: “That thing you said”, it helped others understand blah blah',
    ],
    suggestions: [
      'do this suggestion',
      'and this one',
      'and also this',
    ],
  },
  {
    category: 'Engaging Others',
    highlights: [
      'Great job validating others!',
      'When you said: “That thing you said”, it helped others understand blah blah',
    ],
    suggestions: [
      'do this suggestion',
      'and this one',
      'and also this',
    ],
  },
];


const SessionHighlightsContent = ({coachingData}) => {
  return (
    <Box id='session-highlights-content' width="90%"
      sx={{
        display: 'flex',
        mt: 2,
        flexDirection: 'column',
        overflowY: 'auto'}}>
      {coachingData.map((item, index) => {
        const skillData = skillsData.find((skill) => skill.title === item.category);
        if (!skillData) {
          return null;
        }
        return (
          <Box key={index} sx={{flex: 1, mr: 2, mt: 2}}>
            <Box sx={{display: 'flex', alignItems: 'center', mb: 3}}>
              <img src={skillData.image} alt={skillData.imgDesc} width={60} height={60} style={{marginRight: 10}} />
              <Typography variant="h2">{skillData.title}</Typography>
            </Box>
            <Typography variant="body1"><b>Highlights:</b></Typography>
            <List>
              {item.highlights.map((highlight, index) => (
                <ListItem key={index} sx={{p: 0, ml: 5}}>
                  <ListItemText m={0} primary={highlight} />
                </ListItem>
              ))}
            </List>
            <Typography variant="body1"><b>Next time try:</b></Typography>
            <List>
              {item.suggestions.map((suggestion, index) => (
                <ListItem key={index} sx={{p: 0, ml: 5}}>
                  <ListItemText primary={suggestion} />
                </ListItem>
              ))}
            </List>
          </Box>
        );
      })}
    </Box>
  );
};

SessionHighlightsContent.propTypes = {
  coachingData: PropTypes.array,
};

SessionHighlightsContent.defaultProps = {
  coachingData: coachingData,
};

export default SessionHighlightsContent;
