import {Box, Typography, IconButton} from '@mui/material';
import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import EditIcon from '@mui/icons-material/Edit';
import listen from '../../assets/images/FC-Listen.svg';
import {useUserProfile} from '../../context/userProfileContext';
import GroupCard from '../groups/groupCard';
/**
 * LearnAbout component.
 * @return {JSX.Element} The LearnAbout component.
 */
export default function profileContent({handleGroupClick}) {
  const {user} = useAuth0();
  const {userProfile} = useUserProfile();

  const passGroupClick = (roomName) => {
    console.log(`Group clicked from profile contant component: ${roomName}`);
    handleGroupClick(roomName);
  };


  const handleProfileEdit = () => {
    // TBD: add logic to edit profile
  };
  const shareLabel = () => {
    return (
      <Box m={2} display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        width={'90%'}
        justifyItems={'start'}>
        <Box id='profile-focus-areas' display={'flex'}
          flexDirection={'column'}>
          <img src={listen} alt="listen" style={{width: '80px'}} />
          <br></br>
          <Typography variant={'body1'}><b>Listening</b>
          </Typography>
        </Box>
        <Box ml={5}>
          <Typography>Listening skills describe how you understand
           and pay attention to other people.
          </Typography>
          <br></br>
          <Typography variant={'body1'}>
            <span><b>Talk Target: &nbsp;</b></span>
            <span>Show appreciation for or
      agreement with someone else&apos;s idea.</span>
          </Typography>
        </Box>
      </Box>
    );
  };


  return (
    <Box p={2} width='100%' display='flex'
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}>
      <Box display='flex'
        alignItems='center'
        width='100%' justifyContent='center' position='relative'>
        <Typography variant='h5'>
          {user.name}
        </Typography>
        <IconButton
          size="small"
          sx={{
            position: 'absolute',
            right: '10%'}}
          onSubmit={handleProfileEdit}
        >
          <EditIcon />
        </IconButton>
      </Box>
      <hr style={{width: '90%'}}></hr>
      <Box display={'flex'}
        width={'90%'}
        margin={2}
        flexDirection={'row'}
        justifyContent={'left'}>
        <Typography variant='body1'>
          <b>Display Name:</b>
        </Typography>
        <Typography variant='body1'>
          &nbsp;&nbsp;{userProfile.profile.displayName}
        </Typography>
      </Box>
      <Box display={'flex'}
        width={'90%'}
        margin={2}
        flexDirection={'row'}
        justifyContent={'left'}>
        <Typography variant='body1'>
          <b>Age:</b>
        </Typography>
        <Typography variant='body1'>
          &nbsp;&nbsp;{userProfile.profile.age}
        </Typography>
      </Box>
      <Box p={2} width='100%' display='flex'
        justifyContent={'center'}
        flexDirection={'column'}
        alignItems={'center'}>
        <Box display='flex'
          alignItems='center'
          width='100%' justifyContent='center' position='relative'>
          <Typography variant='h5'>
          Focus Areas
          </Typography>
        </Box>
        <hr style={{width: '90%'}}></hr>
      </Box>

      {shareLabel()}
      <Box display='flex'
        alignItems='center'
        width='100%' justifyContent='center' position='relative'>
        <Typography variant='h5'>
          Groups
        </Typography>
      </Box>
      <hr style={{width: '90%'}}></hr>
      <Typography variant='body1'>
        select Demo Group to navigate to the demo.
      </Typography>
      { // right now this is just a single item, in the
        // future we will need to map this from some data
      }
      <GroupCard roomName='prosocial-dev'
        handleGroupClick={passGroupClick}>
      </GroupCard>
    </Box>
  );
}
