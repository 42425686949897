/* eslint-disable max-len */
import React from 'react';
import {Box, Grid, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';

// Define mood with corresponding emojis
const emojiMoods = [
  // Simplified mood categories with emojis
  {label: 'Happy', emoji: '😃'},
  {label: 'Sad', emoji: '😢'},
  {label: 'Angry', emoji: '😠'},
  {label: 'Scared', emoji: '😱'},
  {label: 'Surprised', emoji: '😮'},
  {label: 'Funny', emoji: '😄'},
];

const MoodMeterTable = ({handleMoodClick}) => {
  const [selectedMood, setSelectedMood] = React.useState(null);

  const handleClick = (mood) => {
    setSelectedMood(mood.label);
    handleMoodClick(mood);
  };

  return (
    <Box id='mood-meter-container'
      width='100%' sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <Typography variant='h6' sx={{textAlign: 'center', marginBottom: '20px'}}>{selectedMood}</Typography>
      <Grid id='emoji-map' container spacing={2}>
        {emojiMoods.map((mood, index) => (
          <Grid item xs={2} key={index}>
            <Button
              onClick={() => handleClick(mood)}
              sx={{
                'fontSize': '68px',
                'cursor': 'pointer',
                'background': 'none', // Removes Paper background
                'boxShadow': 'none',
                'border': '1px solid', // Optional: adds a subtle border
                'borderColor': selectedMood === mood.label ? '#ccc' : 'transparent', // Border color to match
                '&:hover': {
                  'background': selectedMood === mood.label ? '#f0f0f0' : '#e0e0e0', // Darker gray on hover for non-selected buttons
                },
              }}
              aria-label={mood.label}// Accessible label for screen readers
            >
              {mood.emoji}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

MoodMeterTable.propTypes = {
  handleMoodClick: PropTypes.func,
};

MoodMeterTable.defaultProps = {
  handleMoodClick: (mood) => {
    console.log(mood.label); // Or any other action
  },
};

export default MoodMeterTable;
