/* eslint-disable max-len */
import axios from 'axios';
import {baseURL} from './setEnvironment';
const removeUnnecessaryFields = (data) => {
  const fieldsToRemove = ['_rid', '_self', '_etag', '_attachments', '_ts'];
  const cleanedData = {...data};

  fieldsToRemove.forEach((field) => {
    delete cleanedData[field];
  });

  return cleanedData;
};

export const updateUserSession = async (sessionDataAll) => {
  try {
    // Clean the session data
    const sessionData = sessionDataAll.sessionDetails;
    console.log('Session data to send to API:', JSON.stringify(sessionData, null, 2));
    const cleanedSessionData = removeUnnecessaryFields(sessionData);

    const requestConfig = {
      url: baseURL() +
          `/user/${cleanedSessionData.user_id}/session/${cleanedSessionData.session_id}/update-session`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify(cleanedSessionData),
    };

    const response = await axios(requestConfig);
    console.log('Response from user session API:', JSON.stringify(response, null, 2));

    if (response.status === 204) {
      return null;
    } else if (response.status === 200) {
      return response.data;
    } else {
      console.log(response.data.status, response);
      throw new Error('Unexpected response status');
    }
  } catch (error) {
    if (error.response) {
      console.error('Error response data:', JSON.stringify(error.response.data, null, 2));
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', JSON.stringify(error.response.headers, null, 2));
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('General error message:', error.message);
    }
    throw error;
  }
};


export default updateUserSession;
