/* eslint-disable max-len */
import React, {useEffect, useState, useMemo, useRef} from 'react';
import ContextualHeader from '../common/contextualHeader.jsx';
import DesktopFooter from '../common/footer.jsx';
import {Box, Grid} from '@mui/material';
import {fetchRoomData} from '../../api/dailyapi';
// import {topNavLinks} from '../../models/navlinks.js';
import VideoApp from './video/videoAppPage.jsx';
import {useAuth0} from '@auth0/auth0-react';
import {DailyProvider} from '@daily-co/daily-react';
import {companyLinks,
  resourcesLinks, partnerLinks} from '../../models/navlinks.js';
import CheckIn from './checkin/checkInPage.jsx';
import VideoError from './video/videoTiles/videoError';
import VideoJoin from './prejoin/videoJoinPage.jsx';
import logger from '../../logger.js';
import getTalkTargetTip from '../../api/getTargetTip.js';
import {useUserSession} from '../../context/userSessionContext.js';
import {CircularProgress} from '@mui/material';
import {useUserProfile} from '../../context/userProfileContext.js';

const STATE_IDLE = 'STATE_IDLE';
const STATE_ERROR = 'STATE_ERROR';
const STATE_JOINED = 'STATE_JOINED';
const STATE_READY = 'STATE_READY';
const STATE_IN_CALL = 'STATE_IN_CALL';

import {Routes, Route} from 'react-router-dom';

/**
 * Renders the main app home page.
 * @return {JSX.Element} - The component JSX.
 */
export default function AppHome() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<AppMain />} />
        <Route path="checkin/" element={<CheckIn />} />
      </Routes>
    </div>
  );
};

/**
 * container page for the video calling app.
 * on load, it will get the access token for the user. Once the token is
 * provided by daily, it will render the video app.
 * @param {Object} props - The component props.
 * @param {Object[]} props.companyLinks - The company links.
 * @param {Object[]} props.resourcesLinks - The resources links.
 * @param {Object[]} props.partnerLinks - The partners links.
 * @return {JSX.Element} - The component JSX.
 */
export function AppMain() {
  const [room, setRoom] = useState();
  const [appState, setAppState] = useState(STATE_IDLE);
  const {user} = useAuth0();
  const {userSession} = useUserSession();
  const {userProfile} = useUserProfile();

  console.log(`user session AppHome: `, userSession);

  useEffect(() => {
    console.log('AppMain component mounted');
    if (userProfile && userSession && userSession.sessionRoom?.room_id && appState === STATE_IDLE && !room) {
      console.log('userProfile and userSession are not null.  Triggering room fetch');
      fetchRoom();
    } 
  }, [userProfile, userSession]);

  // 1. if there is an existing user session room, join that room.
  // 2. if there is no existing user session room, create a new room.
  const fetchRoom = async () => {
    console.log('fetch room triggered from user session load');
    try {
      const roomdata = await fetchRoomData(userProfile.accessToken, userSession.sessionDetails?.is_demo, userSession.sessionRoom?.room_id);
      setRoom(roomdata);
    } catch (error) {
      setAppState(STATE_ERROR);
      logger.error('Error fetching room data', error);
    }
  };

  const triggerTalkTargetTip = async (userId) => {
    logger.info('triggering talk target tip');
    try {
      await getTalkTargetTip(userId);
    } catch (error) {
      logger.error('Error fetching talk target tip', error);
    };
  };

  useEffect(() => {
    if (appState !== STATE_IDLE && appState !== STATE_ERROR) {
      if (room && appState === STATE_JOINED) {
        console.log('room fetched');
        startCall();
      };
    }
  }, [appState]);

  /**
   * start call is triggered when the daily client has joined the room, the app state
   * is updated to join and the userprofile and user session are populated
   * Starts a call for a specific participant.
   * @param {string} participantId - The ID of the participant.
   */
  const startCall = () => {
    console.log('starting call for user and session and meeting',
        JSON.stringify(room));
    user && triggerTalkTargetTip(user.sub);
    setAppState(STATE_IN_CALL);
  };

  /**
   * if there is a room, we initialize the DailyProvider
   * with the room url and token
   * if there is no room, we display a loading spinner
   * if there is an error, we display an error message
   * if the user has not checked in, we display the check-in form
   * if the user has checked in, we display the call screen
   * @return {React.ReactNode} The rendered component.
   */
  const callConditionalRender = () => {
    console.log(`rendering call conditionally for app state: ${appState}`);
    console.log(`room value`, room);

    if (appState === STATE_ERROR) {
      return <VideoError />;
    } else if (appState === STATE_IN_CALL) {
      console.log('returning video app');
      return <VideoApp
        appState={appState}
        setAppState={setAppState}></VideoApp>;
    } else {
      return <VideoJoin setAppState={setAppState}
        sendError={() => setAppState(STATE_ERROR)} />;
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box sx={{position: 'sticky', top: 0, zIndex: 1000}}>
        <ContextualHeader id='contextual-header' />
      </Box>
      {userProfile && userProfile.accessToken ?
          <Grid container direction='row'
            sx={{alignItems: 'center', justifyContent: 'center'}}>
            { room ?
            <DailyProvider url={room.room_url}
              token={room.token} userName={user.name}
              dailyConfig={{useDevicePreferenceCookies: true}}>
              {callConditionalRender()}
            </DailyProvider> :
            <CircularProgress id='loading-spinner'/>}
          </Grid> :
      <Box display={'flex'} m={2} width='100vw' height={'100vh'}
        alignItems={'center'} justifyContent={'center'}>
        <CircularProgress id='loading-spinner' />
      </Box>
      }
      <DesktopFooter id='desktop-footer' companyLinks={companyLinks}
        partnersLinks={partnerLinks}
        resourcesLinks={resourcesLinks}/>
    </Box>

  );
}

AppMain.propTypes = {
};


AppMain.defaultProps = {
};
