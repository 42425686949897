/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  DailyVideo,
  useActiveSpeakerId,
  useAudioTrack,
} from '@daily-co/daily-react';
import classNames from 'classnames';
import {Box} from '@mui/material';

/**
 * Video Tile component
 * @param {string} id - The id of the participant
 * @param {boolean} isScreenShare
 * Whether the participant is sharing their screen
 * @return {JSX.Element}
 */
export default function VideoTile({id}) {
  const activeId = useActiveSpeakerId();
  const audioTrack = useAudioTrack(id);

  return (
    <Box>
      <DailyVideo id='daily-video-tile'
        width={'100%'}
        key={id}
        automirror
        className={classNames({
          active: activeId === id,
        })}
        sessionId={id}
        style={{
          objectFit: 'contain', // CSS style to maintain aspect ratio without overflow
          maxHeight: '400px', // Ensures the video does not exceed the container's height
        }}
      />
      <span id='mute-button' role="img" aria-label={audioTrack.isOff ? 'Muted' : 'Unmuted'}>
      </span>
    </Box>
  );
}


VideoTile.propTypes = {
  id: PropTypes.string.isRequired,
};

VideoTile.defaultProps={
  id: '0',
};
