/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable require-jsdoc */
import React, {useEffect} from 'react';
import {
  useParticipantIds,
  useDailyEvent,
  useParticipantProperty,
} from '@daily-co/daily-react';
import VideoTile from './videoTiles/videoTile';
import {Grid, Box, Typography} from '@mui/material';


const Participant = React.memo(({id}) => {
  const userName = useParticipantProperty(id, 'user_name') || id;

  return (
    <div>
      <VideoTile id={id} />
      <Typography variant='bodylight'
        width={'100%'}
        sx={{position: 'absolute',
          left: '0', bottom: '0'}}>{userName || 'Guest'}</Typography>
    </div>
  );
});

Participant.displayName = 'Participant';

const Call = ({onCameraError}) => {
  const [numTiles, setNumTiles] = React.useState(4);
  const participantIds = useParticipantIds();

  const tileWidth = () => {
    if (participantIds.length > 3) {
      return 3;
    } else {
      console.log('participantIds.length', participantIds.length);
      return 12/participantIds.length;
    }
  };

  useEffect(() => {
    setNumTiles(tileWidth());
  }, [participantIds]);

  useDailyEvent('camera-error', onCameraError);

  return (
    <Grid id='call-container-with-tiles'
      container style={{height: '100%', position: 'relative'}} >
      {participantIds.map((id) => (
        <Grid id='tile-container' item xs={numTiles} key={id}>
          <Box m={1} padding={1} sx={{border: '1px solid white',
            borderRadius: '0', position: 'relative'}}>
            <Participant id={id}/>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};


Call.displayName = 'Call';

export default Call;
