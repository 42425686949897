// src/context/UserProfileContext.js
import React, {createContext, useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useAuth0} from '@auth0/auth0-react';
import getActiveSession from '../api/getUserActiveSession';

const UserSessionContext = createContext(null);

export const UserSessionProvider = ({children}) => {
  // eslint-disable-next-line max-len
  const {isLoading, user, isAuthenticated} = useAuth0();
  const [status, setStatus] = useState('idle');
  const [userSession, setUserSession] = useState({
    sessionRoom: null, sessionDetails: null});
    
  const updateUserSession = (sessionRoom, sessionDetails) => {
    setUserSession({sessionRoom, sessionDetails});
  };

  useEffect(() => {
    const checkActiveSession = async () => {
        if (user && isAuthenticated) {
          setStatus('loading');
          console.log('checking active session');
            try {
                const response = await getActiveSession(user.sub);
                setUserSession(response);
            } catch (error) {
                setStatus('error');
                console.error('Error fetching user session: ', error);
            }
        }
    };

    if (user && isAuthenticated & status === 'idle') {
      console.log('checking active session');
        checkActiveSession();
    }
  }
), [user, isAuthenticated];

useEffect(() => {
  if (userSession) {
  console.log('userSession: ', userSession);
  setStatus('loaded');
  }
 }, [userSession]);

  return (
    // eslint-disable-next-line max-len
    <UserSessionContext.Provider id='user-session-context-provider'
      value={{userSession, setUserSession: updateUserSession}}>
      {!isLoading && children}
    </UserSessionContext.Provider>
  );
};

UserSessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUserSession = () => {
  return useContext(UserSessionContext);
};
