import axios from 'axios';
import {baseURL} from './setEnvironment';

export const getTalkTargetTip = async (userId) => {
  try {
    const requestConfig = {
      url: baseURL() + `/users/${userId}/talk_target_tips`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };

    await axios(requestConfig);
    console.log('Talk target tip request sent successfully.');
  } catch (error) {
    console.error('Error requesting talk target tip:', error);
  }
};

export default getTalkTargetTip;
