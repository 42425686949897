import {Auth0Provider} from '@auth0/auth0-react';
import {useAuth0} from '@auth0/auth0-react';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import logger from './logger';

export const Auth0ProviderWithNavigate = ({children}) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    console.log(`Auth0ProviderWithNavigate: 
    Missing configuration. ${domain} ${clientId} ${redirectUri} ${audience}`);
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
        scope: `profile email demo join:demo view:app
         view:analytics join:video`,

      }}
      onRedirectCallback={onRedirectCallback}
    >
      <TokenFetcher>{children}</TokenFetcher>
    </Auth0Provider>
  );
};

Auth0ProviderWithNavigate.propTypes = {
  children: PropTypes.node.isRequired,
};

const TokenFetcher = ({children}) => {
  console.log('fetching token from backend and setting sessions storage');
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();
  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
          logger.info(`Token audience value: ${audience}`);
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: audience,
              scope: `profile email demo join:demo view:app
                view:analytics join:video`,
            },
          });
          sessionStorage.setItem('accessToken', token);
        } catch (e) {
          logger.info(`Unable to get access token for user: ${e.message}`);
        }
      }
    };

    fetchToken();
  }, [isAuthenticated]);
  return <>{children}</>;
};

TokenFetcher.propTypes = {
  children: PropTypes.node.isRequired,
};
