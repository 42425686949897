/* eslint-disable max-len */
const focusAreas = [
  {
    name: 'Assertiveness',
    description: 'Setting boundaries, saying no, expressing wants and needs',
  },
  {
    name: 'Active Listening',
    description: 'Perspective taking, turn taking, reducing interruptions, validating others',
  },
  {
    name: 'Self-Expression',
    description: 'Articulating ideas, using non-verbal gestures, clear and concise speech, understanding intonation',
  },
  {
    name: 'Emotional Intelligence',
    description: 'Recognizing emotions, understanding triggers, empathy',
  },
  {
    name: 'Conflict Resolution',
    description: 'Negotiation, understanding different viewpoints, finding common ground',
  },
  {
    name: 'Public Speaking',
    description: 'Speaking confidently, engaging audience, structured presentation',
  },
  {
    name: 'Teamwork and Collaboration',
    description: 'Working in groups, shared goals, interdependence',
  },
];

export default focusAreas;

const goals = [
  'Improve Communication Skills',
  'Make friends',
  'Reduce Social Anxiety',
  'Increase Social Confidence',
  'Participate in a fun activity',
  'Be part of a supportive community',
  'Learn to be more assertive',
  'Improve leadership capabilities and influence',
];

export {goals};
