import axios from 'axios';
import {baseURL} from './setEnvironment';

export const getOrCreateSession= async (roomId, userId) => {
  try {
    const requestConfig = {
      url: baseURL() + `/users/${userId}/rooms/${roomId}/user-session`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: {
        create_new: true,
      },
    };

    const response = await axios(requestConfig);
    console.log('response from user session api: ' + JSON.stringify(response));

    if (response.status === 204) {
      console.log('no active session found.  Returning null');
      return null;
    } else if (response.status === 200) {
      return response.data;
    } else {
      console.log(response.data.status, response);
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export default getOrCreateSession;
