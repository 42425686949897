/* eslint-disable max-len */

import React from 'react';
import se from '../assets/images/FC-Self-Expression-4.svg';
import rb from '../assets/images/FC-Relationship-Building.svg';
import gd from '../assets/images/FC-Group-Dynamics.svg';
import l from '../assets/images/FC-Leadership.svg';
import listen from '../assets/images/FC-Listen.svg';
import assert from '../assets/images/FC-Assertiveness.svg';
import conflict from '../assets/images/FC-Conflict-Resolution.svg';
import engage from '../assets/images/FC-Engage.png';
import count from '../assets/images/counters.png';
import timer from '../assets/images/timers.png';
import groupSelect from '../assets/images/group selector.png';
import coach from '../assets/images/convCoach.png';
import alert from '../assets/images/alerts.png';
import checklist from '../assets/images/checklists.png';
import target from '../assets/images/talktarget.png';
import robot1 from '../assets/images/ROBOT-EMOTIONS.svg';
import robot2 from '../assets/images/robot-eye-contact-1.svg';
import robot3 from '../assets/images/robot-speech-transcription.svg';
import gauge from '../assets/images/Gauges.png';

const productData = [
  {
    image: se,
    desc: 'a megaphone on a light purple background',
    name: 'Set your own goals',
    text: 'Whether you want to overcome social anxiety or become a better listener, you choose what to work on.',
  },
  {
    image: rb,
    desc: 'a bridge made of people emojis on a light purple background',
    name: 'Find your people',
    text: 'Connect with others who have the same interests, challenges, and goals as you.',
  }, {
    image: gd,
    desc: 'a small icon of a puzzle piece on a light purple background',
    name: 'Get real time support',
    text: 'Get a much or as little coaching as you need to help you communicate.',
  }, {
    image: l,
    desc: 'a small icon of a graduation cap and tassel on a light purple background',
    name: 'Make real connections',
    text: 'Regular, repeated contact with others leads to lasting friendships.',
  },
];

const featuresData = [
  {
    header: 'Focus Areas and Skills',
    text: `A Focus Area is a group of skills that commonly go together. Socialization is a complex topic. 
    Focus areas can provide a helpful framework for you to understand your strengths and areas for growth. `,

  },
  {

    header: 'Our Models',
    text: `State-of-the-art machine learning models are used to understand what you are communicating and give 
    real-time feedback during conversation.`,
  },
  {

    header: 'Coaching Widgets',
    text: `Coaching widgets are individual components that measure and display information related to each conversational skill, 
    providing information about your strengths and areas for growth. Below are some examples, with many more available in the application.`,
  },
  {
    image: coach,
    header: 'Conversation Coach',
    text: `Conversation coach knows what your strengths and goals are. Our AI calls out your strengths to help you 
    build confidence and to help you appreciate what you are already doing well. It also alerts you to areas where you can focus your energy to grow.`,
  },
  {
    image: target,
    header: 'Talk Targets',
    text: `Talk target a prompts that are only visible to you, and offer you ideas on how to engage and what to say next. 
    Targets are different for every conversation and are unique to your strengths and goals, so someone who is very 
    empathetic and wants to work on decreasing social anxiety, will have different talk targets than someone who is great at 
    engaging people and wants to work on their listening skills.
    `,
  },
  {
    image: groupSelect,
    header: 'Flexible Groups',
    text: (  <>
    Groups are designed and classified along 5 different scales, to make sure we can suggest and match people with groups that are a good fit for their unique needs.
      <br /><br />
    Each group lists which focus areas are well suited to the group, which is helped to suggest groups for you and give you more insight into what you are selecting.
    </>
    ),
  },
];

export {productData, featuresData};

const skillsData = [
  {
    title: 'Self-Expression',
    value: 'Level 1 Skill',
    image: se,
    imgDesc: 'a small icon of headphones on a light purple background',
    description:
      'Self-expression is how you share your own ideas, feelings, and thoughts clearly and authentically. Skills include:',
    skills: [
      'sharing ideas',
      'using non-verbal cues',
      'being clear and concise',
      'varying tone of voice',
    ],
  },
  {
    title: 'Engaging Others',
    value: 'Level 1 Skill',
    image: engage,
    imgDesc: 'a small icon of headphones on a light purple background',
    description:
      'Engaging others involves initiating and maintaining interactions that are both meaningful and reciprocal. Skills include:',
    skills: [
      'pacing',
      'eye contact',
      'asking questions',
      'reading non-verbal cues',
      'building on others\' ideas',
    ],
  },
  {
    title: 'Listening',
    value: 'Level 1 Skill',
    image: listen,
    imgDesc: 'a small icon of headphones on a light purple background',
    description:
      'Listening skills describe your ability to understand, validate and pay attention to what other people are saying. Skills include:',
    skills: [
      'perspective taking',
      'Validating Others',
      'turn-taking',
      'active Listening',
      'paraphrasing & reflecting',
      'pausing before speaking',
    ],
  },
  {
    title: 'Assertiveness',
    value: 'Level 2 Skill',
    image: assert,
    imgDesc: 'a small icon of headphones on a light purple background',
    description:
      'Assertiveness skills help you express your opinions, needs, and boundaries clearly while respecting those of others. Skills include:',
    skills: [
      'standing up for yourself and others',
      'setting boundaries',
      'politely disagreeing',
      'communicating your needs and saying no gracefully',
    ],
  },
  {
    title: 'Building Relationships',
    value: 'Level 2 Skill',
    image: rb,
    imgDesc: 'a small icon of headphones on a light purple background',
    description:
      'This focus area deals with fostering meaningful relationships through understanding, trust, and mutual respect. Skills include:',
    skills: [
      'showing empathy',
      'offering sincere compliments',
      'sharing interests and activities',
      'remembering personal details',
      'offering and accepting help',
    ],
  },
  {
    title: 'Conflict Resolution',
    value: 'Level 2 Skill',
    image: conflict,
    imgDesc: 'a small icon of headphones on a light purple background',
    description:
      'Conflict resolution skills involve identifying, addressing, and resolving conflicts in a peaceful and effective manner. Skills include:',
    skills: [
      'finding common ground',
      'negotiation and compromise',
      'de-escalating conflict',
      'managing your emotions',
      'giving constructive feedback',
    ],
  },
  {
    title: 'Group Dynamics',
    value: 'Level 3 Skill',
    image: gd,
    imgDesc: 'a small icon of headphones on a light purple background',
    description:
      'Understanding group dynamics means adapting to the social norms and behaviors that shape group interactions. Skills Include:',
    skills: [
      'collaborative problem solving',
      'recognizing group roles',
      'being flexible to group norms',
      'recognizing hidden agendas or motives',
      'reading the room',
      'encouraging others to participate',
    ],
  },
  {
    title: 'Leadership & Influence',
    value: 'Level 3 Skill',
    image: l,
    imgDesc: 'a small icon of headphones on a light purple background',
    description:
      'Skills equip participants to ethically and effectively influence the thoughts, feelings, and actions of others. Includes:',
    skills: [
      'Understanding perspectives',
      'persuasive communication',
      'building rapport',
      'ensuring all voices are heard',
      'seeking out and adapting to feedback',
    ],
  },
];

export {skillsData};

const modelData = [
  {
    model: 'Speech Transcription',
    image: robot3,
    description:
      'Speech transcription is the process of converting speech to text. First recorded speech is converted to waveforms, and then those waveforms are run through a predictive model to match the forms to words. Challenges include recognizing accents and working with multiple languages. We use speech transcription to:',
    methods: [
      'Understand how quickly or slowly you are speaking',
      'Understand the words and utterances you are using',
      'Communicate to language models for natural language processing',
    ],
  },
  {
    model: 'Emotion Detection',
    image: robot1,
    description:
      'Emotion detection is a family of algorithms that allow us to analyze speech patterns, intonation, facial expressions, and non-verbal cues to interpret how you might be feeling, and provide feedback on how others might perceive you.',
    methods: [
      'Verbal emotion detection algorithms measure speech prosody, which includes syllable emphasis, pitch, and modulation.',
      'Image emotion detection algorithms involve mapping facial expressions to emotions, like happiness, sadness, and anger.',
      'We don\'t want to control how you display emotion, but simply to drive awareness and perception, so that you can advocate for yourself and clarify misunderstandings.',
    ],
  },
  {
    model: 'Eye Contact Detection',
    image: robot2,
    description:
      'Eye contact detection, also known as on-focus detection, is a way of measuring the direction of a person\'s gaze, and in particular whether or not someone\'s gaze is directed at the camera or screen in front of them.',
    methods: [
      'For some neurodiverse people, eye contact is uncomfortable and exhausting. We get it, and we will never coach someone on something that is painful for them. In these cases, we encourage advocacy, mutual respect, and understanding.',
      'For others, eye-contact is not painful and simple reminders or practice understanding when eye contact is important is a great goal. In these cases, we use eye contact detection to coach.',
    ],
  },
  {
    model: 'Language Model Integration',
    image: robot3,
    description:
      'We use large language models to process and understand speech topics, content, and meaning. This is how we can tell what participants are talking about, provide coaching conversation, and set talk targets.',
    methods: [],
  },
];

export {modelData};

const widgetsData = [
  {
    widget: 'Counters',
    image: count,
    description: 'Used to track frequency of target events.',
  },
  {
    widget: 'Gauges',
    image: gauge,
    description: 'Used to track and measure progress to a goal or target.',
  },
  {
    widget: 'Checklists',
    image: checklist,
    description: 'Used to remind you of a list of conversational goals.',
  },
  {
    widget: 'Alerts',
    image: alert,
    description: 'Used to let you know when you\'re at or above a threshold.',
  },
  {
    widget: 'Timers',
    image: timer,
    description:
      'Used to visualize how much time you\'ve been talking and remind you to include others.',
  },
];

export {widgetsData};

const attributesData = [
  {
    attribute: 'Structure',
    description:
      'Structure indicates whether the group has a detailed agenda, or more open conversation.',
  },
  {
    attribute: 'Commitment',
    description:
      'Commitment is about the expected length in weeks of group meetings.',
  },
  {
    attribute: 'Intensity',
    description:
      'Intensity relates to the emotional seriousness of the group\'s discussion topics.',
  },
  {
    attribute: 'Engagement',
    description:
      'Engagement is how much participants are expected to participate during the group.',
  },
  {
    attribute: 'Skill Level',
    description:
      'Skill Level is an indicator of the complexity of the group. Beginner groups are good for people who are new to the platform or have higher support needs in general.',
  },
];

export {attributesData};
