// TODO: use a better attribute than 'age' to see
// if we need to redirect to learn about page
import React, {useState, useEffect} from 'react';
import AppHeader from '../common/appHeader';
import DesktopFooter from '../common/footer';
import {Box} from '@mui/material';
import AppLeftNav from './appLeftNav';
import FirstRunExperience from './profileFirstRunExperience';
import ProfileContent from './profileContent';
import {useUserProfile} from '../../context/userProfileContext';
import {useUserSession} from '../../context/userSessionContext';
import {useNavigate} from 'react-router-dom';
import getOrCreateSession from '../../api/getOrCreateUserSession';
import JoinActiveSessionDialog from './joinActiveSessionDialog';
/**
 * Renders the profile component.
 * @return {JSX.Element} The profile component.
 */
export default function Profile() {
  const {userProfile} = useUserProfile();
  const {userSession, setUserSession} = useUserSession();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  console.log('modal state: ', showModal);

  useEffect(() => {
    const modalShown = sessionStorage.getItem('modalShown');
    console.log('modalShown: ', modalShown);
    if (!modalShown && userSession && userSession.sessionDetails &&
          userSession.sessionDetails.session_status === 'active') {
      setShowModal(true);
      sessionStorage.setItem('modalShown', 'true');
    }
    userSession &&
    console.log(`got session from context:
      ${JSON.stringify(userSession)}`);

    userProfile &&
    console.log(`got user from context: ${JSON.stringify(userProfile)}`);
  }, [userSession]);

  const closeModal = () => {
    setShowModal(false);
  };

  /**
   * Navigates the user to the appropriate session
   *  page based on the user's session details.
   * If the user has a valid session checkin,
   *  they are navigated to the main app page.
   * Otherwise, they are navigated to the session checkin page.
   */
  function navigateToGroup() {
    console.log('navigating to group');
    sessionStorage.setItem('modalShown', 'false');
    if (userSession.sessionDetails && userSession.sessionDetails.checkin) {
      console.log('navigating to session already checked in', userSession);
      navigate(`/app`);
    } else {
      console.log('navigating to session checkin page', userSession);
      navigate(`/app/checkin`);
    }
  }

  const goToActiveSession = () => {
    console.log('joining active session');
    setShowModal(false);
    if (userSession && userSession.sessionRoom &&
      userSession.sessionRoom.room_status === 'active') {
      navigateToGroup();
    }
  };

  const retrieveSession = async (roomName) => {
    if (userProfile) {
      console.log('getting new session for userProfile', userProfile);
      const data = await getOrCreateSession(roomName, userProfile.profile.id);
      console.log(`Retrieved user session data for new session:
        ${JSON.stringify(data.sessionRoom, data.sessionDetails)}`);
      setUserSession(data.sessionRoom, data.sessionDetails);
      navigateToGroup();
    } else {
      console.log('no userProfile found! unable to retrieve session');
      // show modal for not logged in, returning to home page
      navigate('/home');
    };
  };

  const age = userProfile && userProfile.profile && userProfile.profile.age;

  if (userProfile) {
    return (
      <div id='user-profile-home'
        style={{backgroundColor: '#F6F6F6',
          width: '100vw',
          height: '100%'}}>
        <AppHeader logoWidth={150}></AppHeader>
        <JoinActiveSessionDialog id='join-active-session-dialog'
          showModal={showModal}
          closeModal={closeModal}
          goToActiveSession={goToActiveSession}>
        </JoinActiveSessionDialog>
        <Box mt={'20px'}
          mb={'20px'}
          width={'calc(100% - 40px)'}
          display={'flex'}
          flexDirection={'row'}>
          <Box
            width={'260px'}>
            <AppLeftNav />
          </Box>
          <Box
            ml={'10px'}
            width={'calc(100% - 270px)'}>
            <Box style={{width: '100%',
              height: '100%',
              backgroundColor: 'white',
              border: '0.5px solid rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'}}>
              {!age?
             (<FirstRunExperience id='first-run-experience'>
             </FirstRunExperience>) :
            <ProfileContent id='profile-content'
              handleGroupClick={retrieveSession}>
            </ProfileContent>}
            </Box>
          </Box>
        </Box>
        <DesktopFooter></DesktopFooter>
      </div>
    );
  } else {
    return (
      <div>Loading...</div>
    );
  }
};
