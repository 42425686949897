/* eslint-disable max-len */
// src/context/UserProfileContext.js
import React, {createContext, useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useAuth0} from '@auth0/auth0-react';
import getUserProfile from '../api/getProfile';

const UserProfileContext = createContext(null);

export const UserProfileProvider = ({children}) => {
  const {isLoading, user, isAuthenticated} = useAuth0();
  const [userProfile, setUserProfile] = useState({profile: null, accessToken: null});

  const updateUserProfile = (profile, accessToken) => {
    setUserProfile({profile, accessToken});
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user && isAuthenticated) {
        console.log('fetching user profile');
        try {
          const profile = await getUserProfile(user.sub);
          setUserProfile({profile, accessToken: sessionStorage.getItem('accessToken')});
      } catch (error) {
          console.error('Error fetching user profile: ', error);
        }
      }
  };

    if (user && isAuthenticated) {
        fetchUserProfile();
    }

}, [user, isAuthenticated]);

useEffect(() => {
  if (userProfile) {
    console.log('userProfile fetched in context hook: ', userProfile);
  }
}, [userProfile]);


  return (
    <UserProfileContext.Provider
      id='user-profile-context-provider'
      value={{userProfile, setUserProfile: updateUserProfile}}>
      {!isLoading && children}
    </UserProfileContext.Provider>
  );
};

UserProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUserProfile = () => {
  return useContext(UserProfileContext);
};
