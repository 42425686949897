/* eslint-disable max-len */

import React, {
  useRef,
  useMemo,
  useState,
} from 'react';

import {Box, Grid} from '@mui/material';
import Call from './videoCall';
import {DailyAudio} from '@daily-co/daily-react';
import VideoControls from './videoControls';
import PropTypes from 'prop-types';
import ChatStream from '../chat/chatstream';
import TargetTips from '../talkTargets/talkTargetTips';
import {WidgetBar} from '../../marketingSite/sections/widgetBar';
import TimelineAgenda from '../talkTargets/timelineAgenda';
import useEventSource from '../../../hooks/useEventSource';

import {useLocalSessionId} from '@daily-co/daily-react';

/* We decide what UI to show to users based on the
state of the app, which is dependent on the state of the call
object: see line 137. */
const STATE_IN_CALL = 'STATE_IN_CALL';
const STATE_ERROR = 'STATE_ERROR';

/**
 * Video App page, nests between headers on the App Home Page.
 * conditional rendering based on the state of the app, which is
 * dependent on the state of the call object.
 * @return {JSX.Element} - Rendered component
 */
const VideoApp = ({
  appState,
  setAppState,
}) => {
  const [timelineData, setTimelineData] = useState([]);
  const callRef = useRef(null);
  const serverEndpoint = process.env.REACT_APP_PROSOCIAL_BACKEND;
  const coachEventRef = useRef(null);
  const localSessionId = useLocalSessionId();
  console.log(`initializing timeline data in videoapp component ${timelineData}`);
  const coachEndpoint = useMemo(() => {
    return localSessionId ?
      `${serverEndpoint}/users/${localSessionId}/feedback/` :
      // `${serverEndpoint}/users/test/feedback/` :
      null;
  }, [localSessionId, serverEndpoint]);

  const {chatHistory, metricData, targetPrompt} = useEventSource(
      coachEndpoint,
      coachEventRef,
      setTimelineData,
  );

  return (
    <Grid container direction='row'>
      <Grid id='video-app-page-container' item xs={12} sx={{padding: '20px'}}>
        <TimelineAgenda timelineData={timelineData}/>
      </Grid>
      <Grid item xs={8}>
        <Grid container direction='row'
          sx={{
            minHeight: {
              xs: '300px', // minHeight on extra-small screens and up
              sm: '400px', // minHeight on small screens and up
              md: '500px', // minHeight on medium screens and up
              lg: '600px', // minHeight on large screens and up
              xl: '700px', // minHeight on extra-large screens and up
            },
            minWidth: {
              xs: '300px', // minHeight on extra-small screens and up
              sm: '400px', // minHeight on small screens and up
              md: '500px', // minHeight on medium screens and up
              lg: '600px', // minHeight on large screens and up
              xl: '700px', // minHeight on extra-large screens and up
            },
            height: '90vh',
            // backgroundColor: 'grey.100',
            overflow: 'hidden',
          }}>
          <Grid item xs={12} p={1} height="85%" ml={2}>
            <div style={{height: '100%'}} ref={callRef}>
              <VideoControls id='video-controls'/>
              <Box
                sx={{
                  flex: 1,
                  backgroundColor: '#000',
                  alignContent: 'center',
                  textAlign: 'center',
                  height: '95%',
                }}
              >
                <Call id='video-call-component' onCameraError={() => setAppState(STATE_ERROR)} />

              </Box>
            </div>
            <DailyAudio id='daily-audio' />
          </Grid>
        </Grid>
      </Grid>
      <Grid id='video-info-pane-right' item xs={4}
        sx={{
          overflowY: 'auto',
          minHeight: {
            xs: '300px', // minHeight on extra-small screens and up
            sm: '400px', // minHeight on small screens and up
            md: '500px', // minHeight on medium screens and up
            lg: '600px', // minHeight on large screens and up
            xl: '700px', // minHeight on extra-large screens and up
          },
          height: '90vh',
        }}>
        <Box padding={'20px'} sx={{
          height: '98%',
          border: '0px solid black',
          background: 'white',
        }}>
          <TargetTips id='talk-target-tips' talkTargetTip={targetPrompt}/>
          <ChatStream id='chat-stream' chatHistory={chatHistory} />
        </Box>
      </Grid>
      <Grid item xs={12}
        height="15%"
        sx={{
          overflowY: 'auto',
        }}>
        <Box padding={'0px'} marginLeft={'100px'} sx={{
          border: '0px solid red',
        }}>{appState != STATE_IN_CALL && <WidgetBar data={metricData} />}
        </Box>
      </Grid>
    </Grid>
  );
};

VideoApp.propTypes = {
  localSessionId: PropTypes.string,
  appState: PropTypes.string,
  setAppState: PropTypes.func,
};

export default VideoApp;
