/* eslint-disable max-len */
import {useEffect, useRef, useState} from 'react';
import logger from '../logger';
const MAX_MESSAGES = 100;
const DEFAULT_DATA =
  [
    {
      icon: 'love', label: 'Coach Bot', timestamp: '2023-04-01T15:30:00.000Z',
      message: 'Positive feedback will look like this',
    },
    {
      icon: 'notify', label: 'Coach Bot', timestamp: '2023-04-01T15:30:00.000Z',
      message: 'Suggestions will look like this',
    },

    {
      icon: 'alert', label: '', timestamp: '2023-04-01T15:30:00.000Z',
      message: 'Alert messages will look like this',
    },
  ];
;

const TARGET_DATA = {
  icon: 'target', label: 'Validate Someone',
  timestamp: '2023-04-01T15:30:00.000Z',
  // eslint-disable-next-line max-len
  message: 'This is a suggestion for how to validate someone.  Click the refresh icon to try a different suggestion.',
};
/**
 * Updates the chat history with a new message.
 * @param {Array} chatHistory - The current chat history.
 * @param {Object} newMessage - The new message to be added to the chat history.
 * @return {Array} - The updated chat history.
 */
function updateChatHistory(chatHistory, newMessage) {
  console.log(`updating chat history with new message ${JSON.stringify(newMessage)} `);
  if (!Array.isArray(chatHistory)) {
    logger.error('chatHistory is not an array', chatHistory);
    chatHistory = [];
  }
  if (chatHistory == DEFAULT_DATA || chatHistory == []) {
    return [newMessage];
  } else {
    const updatedHistory = [...chatHistory, newMessage];
    logger.info('Updated chat history', updatedHistory);
    if (updatedHistory.length > MAX_MESSAGES) {
      return updatedHistory.slice(1); // Remove the oldest message
    }
    return updatedHistory;
  }
}

const useEventSource = (endpoint, setEventState, setTimelineData) => {
  const [chatHistory, setChatHistory] = useState(DEFAULT_DATA);
  const [targetPrompt, setTargetPrompt] = useState(TARGET_DATA);
  const [metricData, setMetricData] = useState([]);
  const lastEndpoint = useRef(endpoint);
  logger.info('setting up event source ' + lastEndpoint.current);

  useEffect(() => {
    // Only run if the event source has not already been set up
    if (setEventState.current) {
      logger.info('Event source state: ' + setEventState.current.readyState);
      return;
    }
    lastEndpoint.current = endpoint;

    const eventSource = new EventSource(endpoint);
    setEventState.current = eventSource;

    const onEnd = (event) => {
      logger.info('Ended coach event source for ' + endpoint);
      eventSource.close(); // Close the EventSource
    };

    eventSource.addEventListener('coach_bot_update',
        (event) => {
          const data = JSON.parse(event.data);
          logger.info('Received coaching messages from app', data);
          const newMessage = data.feedback;
          const ts = data.id.substring(3);
          logger.info('Feedback', newMessage);
          for (let i = 0; i < newMessage.length; i++) {
            newMessage[i].label = 'Coach Bot';
            newMessage[i].icon = newMessage[i].type ==
              'positive' ? 'love' : 'notify';
            newMessage[i].timestamp = ts;
            setChatHistory((chatHistory) =>
              updateChatHistory(chatHistory, newMessage[i]));
          }
        });

    eventSource.addEventListener('talk_target_status',
        (event) => {
          const data = JSON.parse(event.data);
          logger.info('Received talk target status update from app', data);
          const newMessage = data;
          logger.info('Talk target status', newMessage);
          if (newMessage.target_met) {
            newMessage.label = '';
            newMessage.icon = 'alert';
            newMessage.message =
              'Talk target achieved!' + newMessage.explanation;
            newMessage.timestamp = newMessage.id.substring(3);
            setChatHistory((chatHistory) =>
              updateChatHistory(chatHistory, newMessage));
            // Update timeline data
            if (newMessage.target) {
              setTimelineData((prevTimelineData) =>
                prevTimelineData.map((item) => {
                  console.log(`mapping new target ${newMessage.target} to ${item.label}`);
                  if (item.label && item.label.toLowerCase() === newMessage.target.toLowerCase()) {
                    return {...item, checkstate: true};
                  }
                  return item;
                }),
              );
            }
          }
        });

    eventSource.addEventListener('talk_target_tips',
        (event) => {
          const data = JSON.parse(event.data);
          logger.info('Received talk target tip from app', data);
          const newMessage = data;
          logger.info('Talk target tip', newMessage);
          newMessage.label = 'Talk Target';
          newMessage.icon = 'target';
          newMessage.message = newMessage.suggestions[0];
          newMessage.timestamp = newMessage.id.substring(3);
          setTargetPrompt((chatHistory) =>
            updateChatHistory(chatHistory, newMessage));
        });

    eventSource.addEventListener('user_metric_update',
        (event) => {
          const data = JSON.parse(event.data);
          logger.info('Received user metrics from app', data);
          const updatedData = [];
          // eslint-disable-next-line guard-for-in
          for (const key in data) {
            const value = data[key];
            if (typeof value === 'number' && value !== null) {
              updatedData.push({'label': _.startCase(key),
                'value': String(Math.round(value))});
            }
          }
          logger.info('Updated user metrics', updatedData);
          setMetricData(updatedData);
        });

    eventSource.addEventListener('end_event', onEnd);
  }, [endpoint, setEventState]);
  return {chatHistory, metricData, targetPrompt};
};

export default useEventSource;
