/* eslint-disable max-len */
const VERSION = '1.1.0';

/**
 * Get the line number where the logger function is called.
 * @return {string} The line number in the format '/path/to/file:line:column'.
 */
function getLineNumber() {
  const error = new Error();
  const stack = error.stack.split('\n');
  // The relevant line should be the third line of the stack trace
  const callerLine = stack[3] || stack[2]; // Adjust index if necessary
  const lineNumber = callerLine.match(/(\/[^:]+:\d+:\d+)/);
  return lineNumber ? lineNumber[1] : 'unknown line';
}

const logger = {
  info: (...messages) => console.log(`Prosocial Dev: ${VERSION} #####`, getLineNumber(), ...messages),
  warn: (...messages) => console.warn(`Prosocial Dev: ${VERSION} #####`, getLineNumber(), ...messages),
  error: (...messages) => console.error(`Prosocial Dev: ${VERSION} #####`, getLineNumber(), ...messages),
  // Add other console methods as needed
};

export default logger;
