import React from 'react';
import {Grid, Typography, Box} from '@mui/material';
import PropTypes from 'prop-types';
import SocialMediaButtonGroup from '../../common/socials';
import peopleData from '../../../models/people';


const TwoRowGrid = ({imgData, justify, width, height, imgWidth,
  showBackground, showSocials}) => {
  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justifyContent={justify}
      sx={{minHeight: height, margin: 'auto', alignItems: 'stretch'}}
    >
      <Grid item xs={12}>
        <Box
          component="img"
          src={imgData.image}
          alt={imgData.desc}
          sx={{
            width: imgWidth<200? imgWidth: 'auto',
            height: showBackground ? {height} : 'auto',
            borderRadius: '8px',
            display: 'block',
            marginLeft: justify==='start'? '0px': 'auto',
            marginRight: justify==='end'? '0px': 'auto',
          }}
        />
      </Grid>

      <Grid item xs={12} >
        <Box
          width="80%"
          textAlign={justify}
          bgcolor={showBackground ? 'white' : 'transparent'}
          sx={{
            padding: showBackground ? '20px' : '0px',
            border: showBackground ? '1px solid rgba(0, 0, 0, 0.1)' : 'none',
            borderRadius: showBackground ? '8px' : '0px',
            boxShadow: showBackground ?
            '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
            height: showBackground? ({
              xs: 'auto',
              sm: '300px'}) : 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        ><Box flexGrow={1} >
            <Typography variant="h6" gutterBottom>
              {imgData.name}
            </Typography>
            {imgData.title && (
              <Typography variant="subtitle1" gutterBottom>
                {imgData.title}
              </Typography>
            )}
            <Typography variant="body2">{imgData.text}</Typography>
          </Box>
          <Box sx={{display: showSocials? 'block' : 'none'}}>
            <SocialMediaButtonGroup
              links={imgData.links} >  </SocialMediaButtonGroup>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

TwoRowGrid.propTypes = {
  /** *
   * max width of a single column (2x for two column grid etc)
   */
  width: PropTypes.number,
  /** *
   * item alignment
   */
  align: PropTypes.oneOf(['center', 'start', 'end']),
  /** *
   * item justification
   */
  justify: PropTypes.oneOf(['center', 'start', 'end']),
  /** *
   * show background (white w/shadow) on typography elements
   */
  showBackground: PropTypes.bool.isRequired,
  /** *
   * show social media icons
   */
  showSocials: PropTypes.bool.isRequired,
  /** *
   * width of icon image
   */
  imgWidth: PropTypes.number,
  /** *
   * max height of a single row (2x for two row grid etc)
   */
  height: PropTypes.number,
  /** *
   * data to populate grid items
   */
  imgData: PropTypes.shape({
    image: PropTypes.object.isRequired,
    desc: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string, // optional
    text: PropTypes.string.isRequired,
    links: PropTypes.shape({
      facebook: PropTypes.string,
      twitter: PropTypes.string,
      instagram: PropTypes.string,
      linkedin: PropTypes.string,
      medium: PropTypes.string,
      github: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

TwoRowGrid.defaultProps = {
  width: 300,
  justify: 'center',
  height: 200,
  imgWidth: 200,
  showBackground: true,
  showSocials: false,
  imgData: peopleData,
};

export default TwoRowGrid;
