import React from 'react';
import {Box, Grid} from '@mui/material';
import {skillsData} from '../../../models/product';
import FocusArea from '../cards/focusArea';
import PropTypes from 'prop-types';

const FocusGrid = (data) => {
  return (
    <section id="focusAreas">
      <Box sx={{flexGrow: 1, padding: '20px'}} >
        <Grid container spacing={2}>
          {skillsData.map((data, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <FocusArea skills={data} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </section>
  );
};

FocusGrid.propTypes = {
  /** *
 * data to populate grid items
 */
  data: PropTypes.shape(
      {
        title: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        imgDesc: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        skills: PropTypes.arrayOf(PropTypes.string).isRequired,
      },
  ),
};

FocusGrid.defaultProps = {
  data: skillsData,
};


export default FocusGrid;
