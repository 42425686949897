import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Menu, MenuItem, IconButton} from '@mui/material';
import Logo from './logo.jsx';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useAuth0} from '@auth0/auth0-react';
import {useTheme} from '@mui/material/styles';
/**
 * Header component
 * @return {JSX.Element} - Rendered component
 */
export default function AppHeader(
    {logoWidth}) {
  const {isAuthenticated, isLoading, logout} = useAuth0();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <section id="AppHeader" style={{backgroundColor: '#fff'}}>
      <Box
        sx={{
          boxShadow: 3,
          maxHeight: '100px',
          display: 'flex',
          justifyContent: {xs: 'center', sm: 'Space-between'},
          alignItems: 'center',
          width: '100%',
          flexWrap: 'wrap',
          bgcolor: '#ffffff',
          flexDirection: {xs: 'column', sm: 'row'},
        }}
      >
        <Box sx={{marginRight: {xs: '10px', sm: '200px'}}}>
          <a href="/">
            <Logo width={logoWidth}></Logo>
          </a>
        </Box>
        <Box sx={{marginRight: '20px', marginBottom: '10px'}}>
          {isAuthenticated && !isLoading && (
            <div>
              <IconButton
                onClick={handleMenuClick}
                style={{color: theme.palette.primary.main}}
              >
                <AccountCircleIcon style={{fontSize: '3rem'}} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Box>
      </Box>
    </section>
  );
}

AppHeader.propTypes = {

  /*
    **width of logo
    */
  logoWidth: PropTypes.number,


};

AppHeader.defaultProps = {

  logoWidth: 150,


};

