
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import ContextualHeader from '../../common/contextualHeader';
import DesktopFooter from '../../common/footer';
// import {topNavLinks} from '../../models/navlinks.js';
import {Box, Link, Typography} from '@mui/material';

import PropTypes from 'prop-types';

/**
 * Renders a page that indicates that the website is coming soon.
 * @param {Object} props - The props that are passed to this component.
 * @param {Array} props.companyLinks
 *  - The links for the company section of the footer.
 * @param {Array} props.resourcesLinks
 *  - The links for the resources section of the footer.
 * @param {Array} props.partnersLinks
 *  - The links for the partners section of the footer.
 * @param {number} props.logoWidth - The width of the logo in the header.
 * @return {JSX.Element} - The rendered component.
 */
export default function Soon(
    {companyLinks, resourcesLinks, partnersLinks, logoWidth}) {
  return (
    <div>
      <Box sx={{position:
        'sticky', top: 0, zIndex: 1000}}>
        <ContextualHeader />
      </Box>

      <Typography variant='h2' align='center' my='50px'>
                Page Coming Soon!
      </Typography>
      <Typography variant='body1' align='center' textAlign='center' my='50px'>
                ProSocial is under development.
                Please check back soon for more content.
        <br />
        <br />
        <Link component={RouterLink} to='/'>HOME</Link>
      </Typography>

      <DesktopFooter
        companyLinks={companyLinks}
        resourcesLinks={resourcesLinks}
        partnersLinks={partnersLinks}
      />
    </div>
  );
}

Soon.propTypes = {
  companyLinks: PropTypes.array,
  resourcesLinks: PropTypes.array,
  partnersLinks: PropTypes.array,
  logoWidth: PropTypes.number,
};
