import React, {useState} from 'react';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import {sendEmail} from '../../../api/emailadmin';
import focusAreas, {goals} from '../../../models/waitlist';

/**
 * set and send form data to email API
 * @return {JSX.Element} - Rendered component
 */
function WaitlistForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    reason: 'Myself',
    goals: {},
    focusAreas: {},
  });

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setFormData((prevState) => ({...prevState, [name]: value}));
  };

  const handleCheckboxChange = (event) => {
    const {name, checked} = event.target;
    setFormData((prevState) => ({
      ...prevState,
      goals: {...prevState.goals, [name]: checked},
    }));
  };

  const handleFocusAreaChange = (event) => {
    const {name, checked} = event.target;
    setFormData((prevState) => ({
      ...prevState,
      focusAreas: {...prevState.focusAreas, [name]: checked},
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Constructing the message string:
    const goalsSelected = Object.keys(formData.goals)
        .filter((goal) => formData.goals[goal])
        .join(', ');
    const focusAreasSelected = Object.keys(formData.focusAreas)
        .filter((area) => formData.focusAreas[area])
        .join(', ');
    const message = `
          Waitlist Signup Form Submission
          Name: ${formData.firstName} ${formData.lastName}
          Email: ${formData.email}
          Signing Up For: ${formData.reason}
          Goals Selected: ${goalsSelected}
          Focus Areas Selected: ${focusAreasSelected}
      `;

    // Constructing the data to send to API:
    const dataToSend = {
      name: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      message: message,
    };

    // Call the sendEmail function and handle the response:
    const responseMessage = await sendEmail(dataToSend);
    console.log(responseMessage);
  };
  return (
    <div>
      <Box width="100%" display="flex" justifyContent="center">
        <Box
          my="100px"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: {xs: '95%', sm: '80%'},
          }}
        >
          <Typography variant="h4" align="center">
            Join Our Waitlist <br></br>
            <br></br>
          </Typography>
          <Typography variant="h5" align="center">
            You&apos;re First In Line!<br></br>
            <br></br>
          </Typography>
          <Typography variant="body1" align="center">
            Thank you for your interest in ProSocial! Our waitlist users will
            receive updates on our development progress, early access to groups
            in Beta, and a free unlimited subscription to our services for up to
            one year.<br></br>
            <br></br>
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="First name"
                  variant="outlined"
                  name="firstName"
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Last name"
                  variant="outlined"
                  name="lastName"
                  onChange={handleInputChange}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                  variant="outlined"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>
                    Are you joining for yourself, or someone else?
                  </InputLabel>
                  <Select
                    name="reason"
                    label="Are you joining for yourself, or someone else?"
                    onChange={handleInputChange}
                    required
                  >
                    <MenuItem value="Myself">Myself</MenuItem>
                    <MenuItem value="Someone Else">Someone Else</MenuItem>
                    <MenuItem value="Not Sure Yet">Not Sure Yet</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">
                    Which of the goals below are most important to you right
                    now? Select all that apply.<br></br>
                  </Typography>
                  {goals.map((goal) => (
                    <FormControlLabel
                      key={goal}
                      control={
                        <Checkbox name={goal} onChange={handleCheckboxChange} />
                      }
                      label={goal}
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Which of the following focus areas are you interested in
                  practicing or improving?<br></br>
                  <br></br>
                </Typography>

                {focusAreas.map((focusArea, index) => (
                  <Box my='20px' key={index} sx=
                    {{display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'start'}}>
                    <FormControlLabel
                      key={focusArea.name}
                      control={
                        <Checkbox
                          name={focusArea.name}
                          onChange={handleFocusAreaChange}
                        />
                      }
                      label={
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start'}}>
                          <strong>{focusArea.name}</strong>
                          <span style={{marginTop: '5px'}}>
                            {focusArea.description}
                          </span>
                        </Box>
                      }

                    />
                  </Box>
                ))}
              </Grid>

              <Grid item xs={12}>
                <Box width="100%" display="flex" justifyContent="center">
                  <Button variant="contained" color="primary" type="submit">
                    Join Waitlist
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default WaitlistForm;
