import {featuresData} from '../models/product';

const companyLinks = [
  {name: 'About Us', path: 'https://www.dev.prosocial.care/#team'},
  {name: 'Media', path: '/soon'},
  {name: 'Careers', path: '/soon'},
  {name: 'Contact Us', path: '/contact'},
];

const resourcesLinks = [
  {name: 'Product Information', path: '/product'},
  {name: 'Use Cases', path: '/soon'},
  {name: 'Research', path: '/soon'},
  {name: 'For Parents', path: '/soon'},
];

const partnerLinks = [
  {name: 'Washington Autism Alliance', path: 'https://washingtonautismalliance.org/'},
  {name: 'I-Can', path: 'https://i-can.center/'},
  {name: 'Innov8', path: 'https://www.innov8.care/'},
];

const topNavLinks = [

  {name: 'Product', path: '/product', variant: 'text'},
  {name: 'Team', path: '/#team', variant: 'text'},

];

const featureLinks = featuresData.map((feature) => ({
  name: feature.header,
  path: '#' + feature.header,
  variant: 'text',
}));

export {featureLinks, companyLinks, resourcesLinks, partnerLinks, topNavLinks};
