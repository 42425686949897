import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {NavigationMenuDrawer} from './navMenu';
import Logo from '../../common/logo';
import {Link} from 'react-router-dom';
import {featureLinks} from '../../../models/navlinks';

const defaultLinks = featureLinks;
const drawerWidth = 200;


const Comp = () => <div>Inner Component</div>;

/**
 * Responsive drawer component
 * @param {object} props - The props object for the component
 * @param {number} props.width - The width of the full drawer
 * @param {array} props.link - The links for navigation drawer pass down
 * @param {string} props.label - The title text for top nav
 * @param {string} props.title - The title for the navigation drawer
 * @param {elementType} props.InnerComponent - The inner frame of nav drawer
 * @return {JSX.Element} - The responsive drawer component
 */
function ResponsiveDrawer({width,
  link, label, title, InnerComponent, ...props}) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = <NavigationMenuDrawer links={link} title={title} />;


  const container = undefined;

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: {sm: `calc(100% - ${drawerWidth}px)`},
          ml: {sm: `${drawerWidth}px`},
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr: 2, display: {sm: 'none'}}}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{flexGrow: 1,
            flexDirection: 'row', alignContent: 'center',
            justifyContent: 'stretch'}}>
            <Typography variant='h6'>
            Product Features
            </Typography>

          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with
        js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            'display': {xs: 'block', sm: 'none'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            'display': {xs: 'none', sm: 'block'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
          open
        >
          <Box my='20px' mx='5px'>
            <Link to={'/'}><Logo width={150}></Logo></Link>
          </Box>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
      >
        <Toolbar />
        <InnerComponent {...props}></InnerComponent>

      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  width: PropTypes.number.isRequired,
  link: PropTypes.array.isRequired,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  InnerComponent: PropTypes.elementType,
};

ResponsiveDrawer.defaultProps = {
  width: drawerWidth,
  link: defaultLinks,
  label: 'Responsive Drawer',
  InnerComponent: Comp,
  title: 'Product Features',
};

export default ResponsiveDrawer;
