import axios from 'axios';
import {baseURL} from './setEnvironment';

export const getSessionSummary = async (userId, sessionId) => {
  try {
    const requestConfig = {
      url: baseURL()+`/users/${userId}/sessions/${sessionId}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      }};

    const response = await axios(requestConfig);
    console.log('response from get session: ' + JSON.stringify(response));

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response.data.status, response);
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export default getSessionSummary;
