/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import logger from '../../../logger';
import RefreshIcon from '@mui/icons-material/Refresh';


const talkTargetTip = [{
  target: 'share', suggestions:
  [`Mary, in accommodating your 'share' target, you could present a new idea about a different user feature for the app. For example, you might ask the group, 'What if we added a gamified reward system within the app for meeting certain conversational goals?`],
  UserId: 'e7650704-a65c-4d12-a069-a40936bf1d5e',
  AuthUserId: 'google-oauth2|108298186793951337354',
  UserName: 'Mary Schwaber',
  label: 'Talk Target',
  icon: 'target',
  timestamp: '2024-05-29T23:47:15.770154',
  message: 'Mary, in accommodating your "share" target, you could present a new idea about a different user feature for the app. For example, you might ask the group, "What if we added a gamified reward system within the app for meeting certain conversational goals?"',
}];

const TargetTips = ({talkTargetTip}) => {
  // Use useMemo to optimize performance for large chat histories
  logger.info(`got talk target tip to stream in ${JSON.stringify(talkTargetTip)}`);


  return (
    <Grid id='talk-target-tips'
      container
      direction="column"
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        position: 'relative',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        maxWidth: '45vw',
        p: 2,
        overflowY: 'auto',
        marginBottom: '20px',
      }}>
      <Grid item xs={12}>
        <Typography variant='h6'>
        Talk Target Tips
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1' >
          Current Target
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{marginBottom: 2}}>
        <Typography variant='caption' >
          {talkTargetTip[0] && talkTargetTip[0].target}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{display: 'flex', flexWrap: 'nowrap', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'row'}}>
          <Typography variant='body1'>
            Suggestions
          </Typography>
          <RefreshIcon />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption'>
          {talkTargetTip[0] && talkTargetTip[0].message}
        </Typography>
      </Grid>
    </Grid>
  );
};

TargetTips.propTypes = {

  talkTargetTip: PropTypes.object,
};

TargetTips.defaultProps = {

  talkTargetTip: talkTargetTip,
};

export default TargetTips;
